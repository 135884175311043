const backToTop = document.querySelector('.js-back-to-top')
if (backToTop) {
  const appearAfterDistance = 1000
  window.addEventListener('scroll', () => {
    if (window.scrollY > appearAfterDistance) {
      backToTop.classList.add('is-visible')
    } else {
      backToTop.classList.remove('is-visible')
    }
  })

  backToTop.addEventListener('click', () => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  })
}
