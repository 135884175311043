/* Instead of using the generic video tag to display video with a poster,
 * which causes inconsistency across browsers,
 * we introduce javascript to get around the problem.
 * */

const videoContainers = document.querySelectorAll('.js-video')

videoContainers.forEach((videoContainer) => {
  const poster = videoContainer.querySelector('img')
  const video = videoContainer.querySelector('video')

  poster.addEventListener('click', () => {
    videoContainer.classList.add('is-played')
    video.play()
  })
})
