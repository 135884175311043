import { AxiosError } from 'axios'

// types
export type ErrorResponse = Partial<{
  message: string
  message_type: string
  otp_required: boolean
  error: string
}>

// utils
export const isOtpInvalidError = (error?: AxiosError<ErrorResponse>) => {
  return error?.response?.status === 401 && error?.response?.data.error === '2fa_invalid'
}

export const isOtpRequiredError = (error: AxiosError<ErrorResponse>) => {
  return error.response?.status === 400 && error.response?.data.otp_required
}
