import styled, { keyframes } from 'styled-components'

import { colors } from '../../features/EventType/variables'

const PentagonAnimation = keyframes`
  from {
    transform: scale(1.3) rotate(0deg);
  }
  to {
    transform: scale(1.3) rotate(360deg);
  }
`

const Container = styled.div`
  margin: 0 14px 0 6px;
  width: 16px;
  height: 16px;
  position: relative;
  background: ${colors.navyBlue};

  -webkit-mask-image: url('data:image/svg+xml;utf8,<svg fill="none" height="600" viewBox="0 0 600 600" width="600" xmlns="http://www.w3.org/2000/svg"><path d="m43.9546 271.641 207.8594-218.1224c11.734-12.584 29.335-16.7787 45.26-10.9061l243.062 90.6045c20.115 7.55 31.849 27.685 27.659 48.658l-49.451 267.619c-2.514 15.94-15.087 29.363-31.011 33.558l-282.455 75.503c-19.277 5.034-40.231-3.355-49.45-20.973l-118.1785-215.605c-9.2196-15.94-5.867-36.913 6.7051-50.336z" stroke="white" stroke-width="60"/></svg>');
  -webkit-mask-position: center;
  -webkit-mask-size: cover;
  mask-image: url('data:image/svg+xml;utf8,<svg fill="none" height="61600" viewBox="0 0 600 600" width="600" xmlns="http://www.w3.org/2000/svg"><path d="m43.9546 271.641 207.8594-218.1224c11.734-12.584 29.335-16.7787 45.26-10.9061l243.062 90.6045c20.115 7.55 31.849 27.685 27.659 48.658l-49.451 267.619c-2.514 15.94-15.087 29.363-31.011 33.558l-282.455 75.503c-19.277 5.034-40.231-3.355-49.45-20.973l-118.1785-215.605c-9.2196-15.94-5.867-36.913 6.7051-50.336z" stroke="white" stroke-width="60"/></svg>');
  mask-position: center;
  mask-size: cover;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: conic-gradient(
      from 90deg at 50% 50%,
      #ffffff,
      #ffffff,
      #ffffff,
      #f7fafe,
      #e3edfb,
      #c4daf8,
      #9dc2f3,
      #72a7ed,
      #468ce8,
      #1c71e2,
      #175ebb,
      #144e9c,
      #114488,
      #104080
    );
    animation: ${PentagonAnimation} 3s infinite linear;
  }
`

export const Spinner = Container
