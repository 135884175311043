/**
 * This was adapted from fast64 npm module (https://github.com/superhuman/fast64)
 *
 * Reasons:
 * * it IS fast, but
 * * it operated on strings instead of Uint8Arrays
 * * there was a bug in the arraybuffer implementation
 */

/*
 Copyright Conrad Irwin <conrad@superhuman.com>
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 http://www.apache.org/licenses/LICENSE-2.0
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
/*
 Copyright Vassilis Petroulias [DRDigit]
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 http://www.apache.org/licenses/LICENSE-2.0
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
// rlookup was created using LookupFactory.buildRlookup(false)
const baseRlookup = [
  65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88,
  89, 90, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114,
  115, 116, 117, 118, 119, 120, 121, 122, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 43, 47, 61,
]
// rlookup was created using LookupFactory.buildRlookup(true)
const baseUrlrlookup = [
  65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88,
  89, 90, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114,
  115, 116, 117, 118, 119, 120, 121, 122, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 45, 95, 61,
]
// lookup was created using LookupFactory.buildLookup()
const baseLookup = [
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  62,
  null,
  62,
  null,
  63,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  null,
  null,
  null,
  64,
  null,
  null,
  null,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  null,
  null,
  null,
  null,
  63,
  null,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  null,
  null,
]

export function encode(buffer: Uint8Array, options?: { url: boolean }): string {
  let position = -1
  let len: number
  let result: Uint8Array
  let _rlookup: number[]
  let nan0: number, nan1: number, nan2: number

  len = buffer.length

  result = new Uint8Array(new ArrayBuffer(Math.ceil(len / 3) * 4))
  _rlookup = baseRlookup
  if (options && options.url) {
    // spot the subtle difference :)
    result = new Uint8Array(new ArrayBuffer(Math.ceil((4 * len) / 3)))
    _rlookup = baseUrlrlookup
  }

  var i = 0
  while (++position < len) {
    nan0 = buffer[position]
    nan1 = buffer[++position]
    result[i++] = _rlookup[nan0 >> 2]
    result[i++] = _rlookup[((nan0 & 3) << 4) | (nan1 >> 4)]
    if (isNaN(nan1)) {
      result[i++] = _rlookup[64]
      result[i++] = _rlookup[64]
    } else {
      nan2 = buffer[++position]
      result[i++] = _rlookup[((nan1 & 15) << 2) | (nan2 >> 6)]
      result[i++] = _rlookup[isNaN(nan2) ? 64 : nan2 & 63]
    }
  }

  return new TextDecoder('utf8').decode(result)
}

export function decode(s: string, options?: any): Uint8Array {
  s = s.replace(/\s/g, '')
  if (s.length % 4) {
    throw new Error(
      'InvalidLengthError: decode failed: The string to be decoded is not the correct length for a base64 encoded string.'
    )
  }
  if (/[^A-Za-z0-9+\/\-_=]/g.test(s)) {
    // eslint-disable-line
    throw new Error(
      'InvalidCharacterError: decode failed: The string contains characters invalid in a base64 encoded string.'
    )
  }
  return decodeCommon(s, options)
}

function decodeCommon(s: string, options: { uint8Array: boolean }): Uint8Array {
  var position = -1
  var array = new ArrayBuffer((s.length / 4) * 3)
  var len: number
  var buffer = new Uint8Array(array)
  var i = 0
  var enc0: number, enc1: number, enc2: number, enc3: number

  len = s.length
  while (++position < len) {
    enc0 = baseLookup[s.charCodeAt(position)] as number
    enc1 = baseLookup[s.charCodeAt(++position)] as number
    buffer[i++] = (enc0 << 2) | (enc1 >> 4)
    enc2 = baseLookup[s.charCodeAt(++position)] as number
    if (enc2 === 64) {
      break
    }
    buffer[i++] = ((enc1 & 15) << 4) | (enc2 >> 2)
    enc3 = baseLookup[s.charCodeAt(++position)] as number
    if (enc3 === 64) {
      break
    }
    buffer[i++] = ((enc2 & 3) << 6) | enc3
  }

  return new Uint8Array(array.slice(0, i))
}

export function urlDecode(input: string, options: any): Uint8Array {
  // Pad out with standard base64 required padding characters
  var pad = input.length % 4
  if (pad) {
    if (pad === 1) {
      throw new Error(
        'InvalidLengthError: Input base64url string is the wrong length to determine padding'
      )
    }
    input += new Array(5 - pad).join('=')
  }

  if (/[^A-Za-z0-9\-_=]/g.test(input)) {
    throw new Error(
      'InvalidCharacterError: urldecode failed: The string contains characters invalid in a base64 encoded string.'
    )
  }

  return decodeCommon(input, options)
}

export function urlEncode(input: Uint8Array) {
  return encode(input, { url: true })
}
