/**
 * When a user hovers an institution search result, the corresponding marker
 * on the map should be highlighted if coords are present.
 */

const highlightCurrentMarkerEvent = document.createEvent('Event')
highlightCurrentMarkerEvent.initEvent('highlightCurrentMarker', true, true)
const unhighlightCurrentMarkerEvent = document.createEvent('Event')
unhighlightCurrentMarkerEvent.initEvent('unhighlightCurrentMarker', true, true)

const registerEventsForHighlightingMapMarker = () => {
  const mapElement = document.querySelector('#map') || document.querySelector('#leaflet-map')
  const practiceTargets = document.querySelectorAll('.js-search-result')

  practiceTargets.forEach((practiceTarget) => {
    const { lat, lon } = practiceTarget.dataset
    if (lat && lon) {
      practiceTarget.addEventListener('mouseenter', (_evt) => {
        mapElement.dispatchEvent(
          new CustomEvent('highlightMarker', {
            detail: {
              lat: lat,
              lon: lon
            }
          })
        )
      })

      practiceTarget.addEventListener('mouseleave', (_evt) => {
        mapElement.dispatchEvent(unhighlightCurrentMarkerEvent)
      })
    }
  })
}

document.addEventListener('mapUpdated', registerEventsForHighlightingMapMarker)

registerEventsForHighlightingMapMarker()
