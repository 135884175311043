// ToDo: use css variables instead
export const colors = {
  dark: '#071e3c',
  light: '#fefefe',
  lightGreyBackground: '#f3f5f7',
  lightBlueBackground: '#e6f0f7',
  navyBlue: '#104080',
  midBlue: '#0067b2',
  blue2: '#d3e4f1',
  grey: '#e0e5eb',
  grey800: '#4e6074'
}
