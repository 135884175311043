import { sha256digest, signBuffer } from './subtle/signing'
import {
  convertMasterKey,
  generateSigningKeyPair,
  exportSigningPrivateKey,
  exportSigningPublicKey,
  importSigningPrivateKey,
} from './subtle/signingKeyHandling'

// Callback to persist an exported key pair. Returns the keyId of that key.
export type PutKeyPair = (privateKey: string, publicKey: string) => Promise<{ keyId: string }>

interface SigningKey {
  keyId: string
  privateKey: CryptoKey
}

export interface EncryptedSigningKey {
  keyId: string
  encryptedPrivateKey: string
}

async function decryptSigningKey(
  masterKey: CryptoKey,
  privateKey?: EncryptedSigningKey
): Promise<undefined | SigningKey> {
  if (!privateKey) {
    return undefined
  }
  const decryptedKey = await importSigningPrivateKey(masterKey, privateKey.encryptedPrivateKey)
  return { keyId: privateKey.keyId, privateKey: decryptedKey }
}

const defaultOnGeneratedKey = () => {
  throw new Error('Signer.onGeneratedKey is not implemented')
}

/**
 * This is responsible for generating signatures of strings and handling the associated RSA key pairs for that.
 * We built this to support signatures on PatientForms in samedi platform/patient-app.
 *
 * It gets instantiated with a signingKey. If no signing key exists, a new key is created and exported on-demand
 * through the onGeneratedKey callback.
 * Exported private keys are encrypted with masterKey, which is an AES key.
 *
 * Use `Signer.build` to create instances of this class.
 */
export class Signer {
  private masterKey: CryptoKey
  private onGeneratedKey: PutKeyPair
  private signingKey?: SigningKey

  private constructor(masterKey: CryptoKey, onGeneratedKey?: PutKeyPair, signingKey?: SigningKey) {
    this.masterKey = masterKey
    this.signingKey = signingKey
    this.onGeneratedKey = onGeneratedKey || defaultOnGeneratedKey
  }

  private async generateAndExportSigningKey(): Promise<SigningKey> {
    const generatedKey = await generateSigningKeyPair()
    const { keyId } = await this.onGeneratedKey(
      await exportSigningPrivateKey(this.masterKey, generatedKey.privateKey),
      await exportSigningPublicKey(generatedKey.publicKey)
    )
    return { keyId, privateKey: generatedKey.privateKey }
  }

  private async ensureSigningKey(): Promise<SigningKey> {
    this.signingKey ||= await this.generateAndExportSigningKey()
    return this.signingKey
  }

  /**
   * Create a JWS signature of a SHA-256 hash of encryptedFormData together with signer name and a timestamp.
   *
   * @param dataToSign payload whose hash should be signed
   * @param name human readable name of the person that created the signature.
   * @param signedAt timestamp of the signature. If not passed, the current time will be used.
   */
  async signData(dataToSign: string, name: string, signedAt?: Date): Promise<string> {
    const signingKey = await this.ensureSigningKey()
    const dataDigest = await sha256digest(dataToSign)
    return await signBuffer(signingKey.privateKey, dataDigest, signingKey.keyId, name, signedAt)
  }

  /**
   * Create a class that can be used to create signatures that can be checked with Verifier.
   *
   * @param onGeneratedKey Callback to be called when a new signing key is generated. This should persist the generated key and return its id.
   * @param existingExportedSigningKey When a valid signing key exists, it should be passed here.
   * @see Verifier
   */
  static async build(
    cryptorMasterKey: Uint8Array,
    onGeneratedKey: PutKeyPair | undefined,
    existingExportedSigningKey: EncryptedSigningKey | undefined
  ): Promise<Signer> {
    const masterKey = await convertMasterKey(cryptorMasterKey)
    const decryptedSigningKey = await decryptSigningKey(masterKey, existingExportedSigningKey)
    return new this(masterKey, onGeneratedKey, decryptedSigningKey)
  }
}
