import classNames from 'classnames'
import React, { useLayoutEffect, useRef, useCallback } from 'react'

export type CharacterInputProp = {
  testId?: string
  disabled?: boolean
  value: string
  isFocused: boolean
  error: boolean
  autoFocus?: boolean
  onArrowRightPress: () => void
  onArrowLeftPress: () => void
  onBackspacePress: () => void
  onChange: (text: string) => void
  onFocus: (text: string) => void
  onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => void
}

export const CharacterInput: React.FC<CharacterInputProp> = ({
  testId,
  disabled = false,
  value,
  isFocused,
  error,
  autoFocus,
  onChange,
  onFocus,
  onArrowRightPress,
  onArrowLeftPress,
  onBackspacePress,
  onPaste
}) => {
  const ref = useRef<HTMLInputElement>(null)

  useLayoutEffect(() => {
    if (!isFocused) {
      return
    }

    ref.current?.focus()
  })

  const handleFocus = useCallback(() => onFocus(value), [onFocus, value])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key === 'ArrowRight') {
        e.preventDefault()
        onArrowRightPress()
      }

      if (e.key === 'ArrowLeft') {
        e.preventDefault()
        onArrowLeftPress()
      }

      if (e.key === 'Backspace') {
        e.preventDefault()
        onBackspacePress()
      }
    },
    [onArrowLeftPress, onArrowRightPress, onBackspacePress]
  )

  const handleClick = useCallback(() => {
    ref.current?.setSelectionRange(value.length, value.length)
  }, [value])

  return (
    <input
      data-testid={testId}
      autoFocus={autoFocus}
      ref={ref}
      className={classNames(
        'w-9 rounded border py-2.5 text-center outline-none focus:border-sky-blue-500 focus:ring-2 focus:ring-sky-blue-500 disabled:border-grey-200 disabled:bg-white disabled:text-grey-500',
        {
          'border-grey-600': !error,
          'border-error-500': error
        }
      )}
      type='text'
      inputMode='numeric'
      disabled={disabled}
      value={value}
      onChange={handleChange}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      onPaste={onPaste}
    />
  )
}

export default CharacterInput
