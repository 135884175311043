import { faCircleInfo, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

// types
export type Variant = 'default' | 'error'

export type Props = {
  variant?: Variant
  className?: string
}

// components
const Container: React.FC<Props> = ({ variant = 'default', className = '', children }) => {
  return (
    <div
      className={classNames(`flex flex-row rounded p-2 ${className}`, {
        'bg-grey-150': variant === 'default',
        'border border-error-500 bg-error-100': variant === 'error'
      })}
    >
      {children}
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Icon: React.FC<Props> = ({ variant = 'default', className = '' }) => {
  return (
    <FontAwesomeIcon
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      icon={getIcon(variant)}
      className={classNames(
        'mr-2 flex h-4 w-4 p-1',
        {
          'text-grey-800': variant === 'default',
          'text-error-600': variant === 'error'
        },
        className
      )}
    />
  )
}

const Text: React.FC<Props> = ({ variant = 'default', className = '', children }) => {
  return (
    <p
      className={classNames(`text-s ${className}`, {
        'text-error-500': variant === 'error'
      })}
    >
      {children}
    </p>
  )
}

const InfoContainer: React.FC<Props> = ({ variant = 'default', className = '', children }) => {
  return (
    <Container className={className} variant={variant}>
      {children}
    </Container>
  )
}

export default Object.assign(InfoContainer, {
  Container,
  Text
})

// utils
const getIcon = (variant: Variant) => {
  if (variant === 'error') {
    return faTriangleExclamation
  }
  return faCircleInfo
}
