import React from 'react'

import { Spinner } from '../Spinner'

interface Props {
  text?: string
}

export const FullscreenSpinner: React.FC<Props> = ({ text }) => (
  <div className='fixed inset-0 z-[999] flex h-full w-full flex-col items-center justify-center backdrop-blur'>
    <Spinner className='min-h-[92px] min-w-[92px]' />
    {text && <p className='pt-5 text-h4 font-semibold text-dark'>{text}</p>}
  </div>
)
