import React, { useEffect, useState } from 'react'

export function useSelfDestruct(expireIn: number, toRender: React.ReactNode) {
  const [shouldRender, setShouldRender] = useState(true)

  // Caveat: Use a <SelfDestruct /> component instead of this hook.
  //         In most cases, React reusing the state of this hook will cause
  //         problematic behaviour.
  useEffect(() => {
    setShouldRender(true)
    const timeoutId = setTimeout(() => {
      setShouldRender(false)
    }, expireIn)
    return () => clearTimeout(timeoutId)
  }, [expireIn])

  return shouldRender ? toRender : null
}
