import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import React, { FormEventHandler, useCallback, useEffect } from 'react'

import InfoContainer from 'components/shared/InfoContainer/InfoContainer'
import { t } from 'i18n'

import CharacterInput from './CharacterInput'
import { ErrorContainer } from './ErrorContainer'
import { FormValues } from './types'
import useCodeInput from './useCodeInput'
import { getIsError, getSafeArrayIndex, validate } from './utils'

export type CodeVerificationProps = {
  disabled?: boolean
  email: string
  onSubmit: (values: FormValues) => void
  onSendCodeAgain: () => void
  isInvalidInput?: boolean
  onChange?: (value: string) => void
}

const CodeVerification: React.FC<CodeVerificationProps> = ({
  disabled = false,
  email,
  onSubmit,
  onSendCodeAgain,
  isInvalidInput = false,
  onChange
}) => {
  const form = useFormik<FormValues>({
    initialValues: {
      code: ''
    },
    validate,
    onSubmit,
    onReset: () => {
      if (!disabled) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        reset()
        onSendCodeAgain()
      }
    }
  })
  const {
    activeIndex,
    reset,
    characterArray,
    goToNextIndex,
    goToPrevIndex,
    handleChange,
    handleBackspacePress,
    handleFocus,
    handlePaste
  } = useCodeInput(form.values.code, form.handleChange('code'))

  useEffect(() => {
    if (!onChange) {
      return
    }
    onChange(form.values.code)
  }, [form.values.code, onChange])

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault()
      form.handleSubmit()
    },
    [form]
  )

  return (
    <form
      data-testid='2fa-confirmation-panel'
      className='flex flex-col items-center gap-4'
      onSubmit={handleSubmit}
    >
      <p dangerouslySetInnerHTML={{ __html: t('login.verify-code.insert-code', email) }} />
      <div>
        <div className='flex flex-row gap-2'>
          {characterArray.map((char, index) => (
            <CharacterInput
              testId={`otp-code-input-${index + 1}`}
              disabled={disabled}
              value={char}
              autoFocus={index === 0}
              onChange={handleChange}
              onArrowRightPress={goToNextIndex}
              onArrowLeftPress={goToPrevIndex}
              onBackspacePress={handleBackspacePress}
              onFocus={handleFocus(index)}
              // eslint-disable-next-line react/jsx-no-bind
              onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                const text = e.clipboardData.getData('Text')
                if (text) {
                  e.preventDefault()
                  handlePaste(text)
                }
              }}
              isFocused={index === getSafeArrayIndex(activeIndex)}
              key={index}
              error={getIsError(form, isInvalidInput)}
            />
          ))}
        </div>
      </div>
      <ErrorContainer
        form={form}
        isInvalidInput={isInvalidInput}
        onSendCodeAgain={form.handleReset}
      />
      <button
        data-testid='submit-otp-code'
        className='w-full max-w-none rounded-lg bg-primary-600 py-2.5 font-semibold leading-5 text-white hover:bg-secondary-500 active:bg-navy-blue disabled:bg-grey-80 sm:w-80 sm:max-w-[xs]'
        disabled={disabled}
        type='submit'
      >
        {t('login.verify-code.continue-btn')}
      </button>
      <div className='w-full'>
        <span>{`${t('login.verify-code.not-recived')} `}</span>
        <button
          data-testid='resend-otp-code'
          className='active:opactity-30 font-bold text-primary-600 underline hover:text-secondary-500 active:text-navy-blue disabled:text-grey-80'
          disabled={disabled}
          onClick={form.handleReset}
        >
          {t('login.verify-code.send-again')}
        </button>
      </div>
      <InfoContainer className='w-full'>
        <div className='flex gap-2'>
          <FontAwesomeIcon icon={faCircleInfo} className='h-4 w-4 p-1 text-grey-800' />
          <div className='flex flex-col'>
            <div>{t('login.verify-code.need-help')}</div>
            <a
              data-testid='contact-support-link'
              className='pt-1 font-semibold text-primary-600 underline hover:text-secondary-500 active:text-navy-blue'
              href={t('login.verify-code.contact-support-url')}
              target='_blank'
              rel='noreferrer'
            >
              {t('login.verify-code.contact-support')}
            </a>
          </div>
        </div>
      </InfoContainer>
    </form>
  )
}

export default CodeVerification
