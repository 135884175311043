import { utf8StringToBytes } from './utils'
import { decode } from './base64'

export enum CipherAlgorithm {
  RSA = 0x01,
  AES = 0x02,
  JSON = 0x04,
}

export enum CipherVersion {
  VER_1 = 0x10,
  VER_2 = 0x20,
}

export interface CipherInfo {
  algorithm: CipherAlgorithm
  version: CipherVersion
  keyId: string
  cipher: Uint8Array
}

/**
 * @param {string} cipher
 */
export function extractCipherInfo(cipher: string): CipherInfo | null {
  // faster than regular expression parsing
  const [algoStr, key] = cipher.split(':', 2)
  if (!algoStr || (!key && algoStr !== 'JSON')) {
    return null
  }

  const data = cipher.slice(algoStr.length + key.length + 2)
  let algo: CipherAlgorithm
  let version: CipherVersion
  let isBase64 = true
  switch (algoStr) {
    case 'RSA':
      algo = CipherAlgorithm.RSA
      version = CipherVersion.VER_1
      break
    case 'RSA2':
      algo = CipherAlgorithm.RSA
      version = CipherVersion.VER_2
      break
    case 'AES':
      algo = CipherAlgorithm.AES
      version = CipherVersion.VER_1
      break
    case 'AES2':
      algo = CipherAlgorithm.AES
      version = CipherVersion.VER_2
      break
    case 'JSON':
      algo = CipherAlgorithm.JSON
      version = CipherVersion.VER_1
      isBase64 = false
      break

    default:
      return null
  }
  return {
    algorithm: algo,
    version: version,
    keyId: key,
    cipher: isBase64 ? decode(data) : utf8StringToBytes(data),
  }
}
