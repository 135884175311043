import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import InfoContainer from 'components/shared/InfoContainer/InfoContainer'

import { InvalidInputError, InvalidInputErrorProps } from './InvalidInputError'
import { FormProps, FormValues } from './types'
import { getIsError } from './utils'

export type ErrorContainerProps = InvalidInputErrorProps & {
  form: FormProps<FormValues>
  isInvalidInput: boolean
}

export const ErrorContainer: React.FC<ErrorContainerProps> = ({
  form,
  isInvalidInput,
  onSendCodeAgain
}) => {
  if (!getIsError(form, isInvalidInput)) {
    return null
  }

  if (isInvalidInput) {
    return <InvalidInputError onSendCodeAgain={onSendCodeAgain} />
  }

  return (
    <InfoContainer variant='error' className='mb-4 w-full'>
      <div className='flex gap-2 text-error-600'>
        <FontAwesomeIcon icon={faTriangleExclamation} className='h-4 w-4 px-1' />
        <InfoContainer.Text className='text-error-500'>{form.errors.code}</InfoContainer.Text>
      </div>
    </InfoContainer>
  )
}
