/* eslint-env browser */
import { setPublicKey, setPrivateKey, KeyPair } from 'node-forge/lib/rsa'

import {
  encode64 as forgeEncode64,
  decode64 as forgeDecode64,
  createBuffer,
  ByteBuffer,
} from 'node-forge/lib/util'

import { encode as encode64 } from './base64'

import symmetric from './symmetric'
import asymmetric from './asymmetric'

import {
  encrypt as subtleSymmetricEncrypt,
  decrypt as subtleSymmetricDecrypt,
  decryptOld as subtleSymmetricDecryptOld,
  loadSessionKey as subtleLoadSessionKey,
  Key,
} from './subtle/symmetric'

import { generateMasterkey, decodeUtf8, ab2str } from './utils'
import { CipherAlgorithm, CipherVersion, extractCipherInfo, CipherInfo } from './extractCipherInfo'
import { getRandomValues } from './subtle/getRandomValues'
import { byteBufferToArrayBuffer } from './byteBufferToArrayBuffer'
import { bigNumToBytes, bigNumToBase64, base64ToBigNum, bytesToBigNum } from './bignum'
import { EncryptedSigningKey, PutKeyPair, Signer } from './signer'

export interface EncryptedKeyPair {
  public_exponent: string
  modulus: string
  private_exponent: string
  p?: string
  q?: string
  dp?: string
  dq?: string
  qinv?: string
  encryption_algorithm?: string
  encryption_key_id?: string
}

const QUEUING_STRATEGY = (globalThis as any).CountQueuingStrategy
  ? new CountQueuingStrategy({ highWaterMark: 1 })
  : undefined

function encryptBignum(bn: jsbn.BigInteger, keyBytes: Uint8Array) {
  return symmetric
    .encrypt(createBuffer(bigNumToBytes(bn)), keyBytes)
    .then((cipher) => forgeEncode64(cipher.getBytes()))
}

function encryptKeyPair(keyPair: KeyPair, keyBytes: Uint8Array): Promise<EncryptedKeyPair> {
  return Promise.all([
    bigNumToBase64(keyPair.privateKey.e),
    bigNumToBase64(keyPair.privateKey.n),
    encryptBignum(keyPair.privateKey.d, keyBytes),
    keyPair.privateKey.p ? encryptBignum(keyPair.privateKey.p, keyBytes) : undefined,
    keyPair.privateKey.q ? encryptBignum(keyPair.privateKey.q, keyBytes) : undefined,
    keyPair.privateKey.dP ? encryptBignum(keyPair.privateKey.dP, keyBytes) : undefined,
    keyPair.privateKey.dQ ? encryptBignum(keyPair.privateKey.dQ, keyBytes) : undefined,
    keyPair.privateKey.qInv ? encryptBignum(keyPair.privateKey.qInv, keyBytes) : undefined,
  ]).then(([e, n, d, p, q, dp, dq, qinv]) => {
    return {
      public_exponent: e,
      modulus: n,
      private_exponent: d,
      p: p,
      q: q,
      dp: dp,
      dq: dq,
      qinv: qinv,
    }
  })
}

type DecryptedAESEncryptionKey = { plain: Uint8Array; webCrypto: Key | undefined }

export default class Cryptor {
  private _userKeyPair?: KeyPair
  private _encryptionKeys: { [id: string]: DecryptedAESEncryptionKey }
  private _keyPairs: { [id: string]: KeyPair }
  private masterKey?: Uint8Array
  private decryptWithWebCrypto: boolean
  private encryptWithWebCrypto: boolean

  public fetchAESKey?: (keyID: string) => Promise<string>
  public fetchRSAKey?: (keyID: string) => Promise<EncryptedKeyPair>

  constructor(decryptWithWebCrypto: boolean = false, encryptWithWebCrypto: boolean = false) {
    this._userKeyPair = null
    this._encryptionKeys = {}
    this._keyPairs = {}

    // @ts-ignore
    const supportsWebCrypto = global.crypto && global.crypto.subtle !== undefined
    this.decryptWithWebCrypto = supportsWebCrypto && decryptWithWebCrypto
    this.encryptWithWebCrypto = supportsWebCrypto && encryptWithWebCrypto
  }

  private async getEncryptionKey(keyID: string): Promise<DecryptedAESEncryptionKey | undefined> {
    if (!this.hasEncryptionKey(keyID) && this.fetchAESKey) {
      const encryptedKeyData = await this.fetchAESKey(keyID)
      await this.addEncryptionKey(keyID, encryptedKeyData)
    }
    return this._encryptionKeys[keyID]
  }

  private async getKeyPair(keyID: string): Promise<KeyPair | undefined> {
    if (!this.hasPracticeKeyPair(keyID) && this.fetchRSAKey) {
      const keyData = await this.fetchRSAKey(keyID)
      await this.addPracticeKeyPair(
        keyID,
        keyData.public_exponent,
        keyData.modulus,
        keyData.private_exponent,
        keyData.p,
        keyData.q,
        keyData.dp,
        keyData.dq,
        keyData.qinv
      )
    }
    return this._keyPairs[keyID]
  }

  initialize(pseudonym: string, pwclienthash: string) {
    this.masterKey = generateMasterkey(pwclienthash, pseudonym)
    return Promise.resolve()
  }

  /**
   * generateUserKeyPair generates a new key pair for the user
   *
   * @param {Function} stepCallback a callback which can be used for a progress indicator
   * @param {Number} keyLength length of the key to generate. default: 2048
   *
   * @returns <Promise<void>> resolves when done
   */
  generateUserKeyPair(stepCallback?: () => void, keyLength?: number) {
    return asymmetric.generateKeyPair(stepCallback, keyLength).then((keyPair) => {
      this._userKeyPair = keyPair
    })
  }

  /**
   * Builds a Signer, which can be used to create signatures which can be checked with a Verifier
   *
   * @param onGeneratedKey Callback to be called when a new signing key is generated. This should persist the generated key and return its id.
   * @param existingSigningKey When a valid signing key exists, it should be passed here.
   * @returns Signer
   */
  getSigner(
    onGeneratedKey?: PutKeyPair,
    existingSigningKey?: EncryptedSigningKey
  ): Promise<Signer> {
    return Signer.build(this.masterKey as Uint8Array, onGeneratedKey, existingSigningKey)
  }

  /**
   * setUserKeyPair loads a previously exported key pair
   *
   * The private parameters (d, p, q, dp, dq, qinv) are expected to be
   * AES-encrypted with the user's masterkey
   *
   * The parameters p, q, dp, dq and qinv are optional. Having access to them
   * speeds up private key operations (decrypt and sign) by orders of magnitude!
   *
   * @param {string} eB64 base64 encoded parameter e (public exponent)
   * @param {string} nB64 base64 encoded parameter n (modulus)
   * @param {string} dB64 base64 encoded parameter d (private exponent), AES-256-CBC encrypted with masterkey
   * @param {string} [pB64] base64 encoded parameter p (private exponent), AES-256-CBC encrypted with masterkey
   * @param {string} [qB64] base64 encoded parameter q (private exponent), AES-256-CBC encrypted with masterkey
   * @param {string} [dpB64] base64 encoded parameter dp (private exponent), AES-256-CBC encrypted with masterkey
   * @param {string} [dqB64] base64 encoded parameter dq (private exponent), AES-256-CBC encrypted with masterkey
   * @param {string} [qinvB64] base64 encoded parameter qinv (private exponent), AES-256-CBC encrypted with masterkey
   *
   * @returns <Promise<void>> resolves when done
   */
  setUserKeyPair(
    eB64: string,
    nB64: string,
    dB64: string,
    pB64?: string,
    qB64?: string,
    dpB64?: string,
    dqB64?: string,
    qinvB64?: string,
    keyID?: string
  ) {
    return this._loadKeyPair(eB64, nB64, dB64, pB64, qB64, dpB64, dqB64, qinvB64)
      .catch((err) => {
        this._userKeyPair = undefined
        throw err
      })
      .then((kp) => {
        this._userKeyPair = kp

        if (keyID) {
          this._keyPairs[keyID] = kp
        }
      })
  }

  /**
   * addPracticeKeyPair loads a previously exported practice key pair for later
   * usage
   *
   * The private parameters (d, p, q, dp, dq, qinv) are expected to be
   * AES-encrypted with an accessible encryptionKey
   *
   * The parameters p, q, dp, dq and qinv are optional. Having access to them
   * speeds up private key operations (decrypt and sign) by orders of magnitude!
   *
   * @param {string} id the ID of the keypair which is loaded. This ID is used to reference the keypair later
   * @param {string} eB64 base64 encoded parameter e (public exponent)
   * @param {string} nB64 base64 encoded parameter n (modulus)
   * @param {string} dB64 base64 encoded parameter d (private exponent), AES-256-CBC encrypted
   * @param {string} [pB64] base64 encoded parameter p (private exponent), AES-256-CBC encrypted
   * @param {string} [qB64] base64 encoded parameter q (private exponent), AES-256-CBC encrypted
   * @param {string} [dpB64] base64 encoded parameter dp (private exponent), AES-256-CBC encrypted
   * @param {string} [dqB64] base64 encoded parameter dq (private exponent), AES-256-CBC encrypted
   * @param {string} [qinvB64] base64 encoded parameter qinv (private exponent), AES-256-CBC encrypted
   *
   * @returns <Promise<void>> resolves when done
   */
  addPracticeKeyPair(
    id: string,
    eB64: any,
    nB64: any,
    dB64: string,
    pB64?: string,
    qB64?: string,
    dpB64?: string,
    dqB64?: string,
    qinvB64?: string
  ) {
    return this._loadKeyPair(eB64, nB64, dB64, pB64, qB64, dpB64, dqB64, qinvB64).then(
      (keyPair) => {
        this._keyPairs[id] = keyPair
      }
    )
  }

  /**
   * hasPracticeKeyPair checks if a keypair with the given ID exists
   *
   * @param {string} id the ID of the keypair to check for
   *
   * @return <boolean>
   */
  hasPracticeKeyPair(id: string | number | symbol) {
    return this._keyPairs.hasOwnProperty(id)
  }

  /**
   * generatePracticeKeyPairEncryptedWithKey generates a new keypair and
   * symmetrically encrypts it with the given key for exporting
   *
   * @param {string} keyId the ID of the `encryptionKey` to encrypt the keypair's private parameters
   * @param {Function} stepCallback a callback for progress notification
   * @param {Number} keyLength length of the key to generate. default: 2048
   *
   * @returns <Promise<EncryptedKeyPair>>
   */
  generatePracticeKeyPairEncryptedWithKey(
    keyId: string,
    stepCallback?: () => void,
    keyLength?: number
  ) {
    const key = this._encryptionKeys[keyId]

    if (!key) {
      return Promise.reject(new Error('NO_SUCH_KEY'))
    }

    return asymmetric
      .generateKeyPair(stepCallback, keyLength)
      .then((keyPair) => encryptKeyPair(keyPair, key.plain))
      .then((encryptedKeyPair) => {
        encryptedKeyPair.encryption_key_id = keyId
        encryptedKeyPair.encryption_algorithm = 'AES2'

        return encryptedKeyPair
      })
  }

  /**
   * exportUserKeyPair exports the userKeypair, encrypted with a new password/pwclienthash
   *
   * This must be used prior to changing the user's password, as the userKeyPair must be changed the same instant.
   *
   * @param {string} pseudonym the user's pseudonym
   * @param {string} pwclienthash a hash of the password. see specs for info on generating that.
   *
   * @returns <Promise<EncryptedKeyPair>>
   */
  exportUserKeyPair(pseudonym: string, pwclienthash: string) {
    const key = generateMasterkey(pwclienthash, pseudonym)
    return encryptKeyPair(this._userKeyPair, key)
  }

  /**
   * decryptEncryptionKey decrypts encryption key data to Uint8Array,
   * which is plain data of the encryption key
   *
   * @param {string} keyData the key's data encrypted and in the format ALGO:KEYID:BASE64DATA
   *                         or BASE64DATA without cipher info, and then the data is decrypted
   *                         with they key pair of current user
   *
   * @returns <Promise<Uint8Array>>
   */
  decryptEncryptionKey(keyData: string): Promise<Uint8Array> {
    return this.decryptToBuffer(keyData).catch((err) => {
      if (err.message === 'NO_CIPHERINFO_FOUND') {
        const decoded = forgeDecode64(keyData)

        return asymmetric
          .decryptToBuffer(decoded, this._userKeyPair)
          .catch(() => {
            return asymmetric.decryptOldToBuffer(decoded, this._userKeyPair)
          })
          .then((plainKey) => {
            if (plainKey.length !== 32) {
              throw new Error(
                'Decrypted key length != 32: "' + plainKey + '" (' + plainKey.length + ')'
              )
            }
            return plainKey
          })
      } else {
        // not an error we can fix, rethrow
        throw err
      }
    })
  }

  /**
   * addEncryptionKey adds a previously saved encryptionKey for later use
   *
   * @param {string} keyId the ID of the key to refer to it later on
   * @param {string} keyData the key's data encrypted and in the format ALGO:KEYID:BASE64DATA
   *
   * @returns <Promise<void>>
   */
  async addEncryptionKey(keyId: string, keyData: string): Promise<void> {
    return this.decryptEncryptionKey(keyData)
      .then((plainKey: Uint8Array) => {
        this._encryptionKeys[keyId] = { plain: plainKey, webCrypto: undefined }

        if (this.decryptWithWebCrypto || this.encryptWithWebCrypto) {
          return subtleLoadSessionKey(plainKey)
        }
      })
      .then((key?: Key) => {
        this._encryptionKeys[keyId].webCrypto = key
      })
  }

  /**
   * generateEncryptionKey generates a random AES key
   *
   * @returns <Promise<string>> resolves to the key ID (it is a negative ID to denote it wasn't saved yet)
   */
  async generateEncryptionKey() {
    const key = getRandomValues(32)

    const id = new Date().getTime() * -1
    this._encryptionKeys[id] = { plain: key, webCrypto: undefined }
    if (this.decryptWithWebCrypto || this.encryptWithWebCrypto) {
      this._encryptionKeys[id].webCrypto = await subtleLoadSessionKey(key)
    }

    return id.toString()
  }

  /**
   * exportEncryptionKey exports an `encryptionKey`, RSA encrypted
   *
   * The key is encrypted with the given RSA public key, optionally in the format
   * of "ALGO:KEYID:BASE64DATA"
   *
   * @param {string} keyId the key to export (must exist!)
   * @param {string} e the public exponent (Base64 encoded)
   * @param {string} n the modulus (Base64 encoded)
   * @param <string|undefined> addKeyPairId when this is a string, it adds "RSA2:<addKeyPairId>:" in front of the result
   *
   * @returns <Promise<string>> resolves to the encrypted key data
   */
  async exportEncryptionKey(
    keyId: string,
    e?: any,
    n?: any,
    addKeyPairId?: string
  ): Promise<string> {
    const key = await this.getEncryptionKey(keyId)

    if (!key) {
      return Promise.reject(new Error('Key ' + keyId + ' not found!'))
    }
    // key is binary, so we must explicitly create a raw buffer
    // otherwise, the binary data will be encoded as utf-8
    const keyBuffer = createBuffer(key.plain, 'raw')

    return this.asymmetricEncrypt(keyBuffer, addKeyPairId, e, n)
  }

  /**
   * decryptToBinary decrypts a ciphertext to a binary string
   *
   * cipertext must be in the form ALGO:KEYID:BASE64DATA!
   *
   * @notice You should rather use `decrypt` which decrypts your data to a nice UTF8 string!
   *
   * @param {string} cipher the ciphertext to decrypt
   *
   * @return <Promise<string>> resolves to the binary decrypted string
   */
  decryptToBinary(cipher: string): Promise<string> {
    return this.decryptToBuffer(cipher).then(ab2str)
  }

  /**
   * decryptToBuffer decrypts a ciphertext to a binary string
   *
   * cipertext must be in the form ALGO:KEYID:BASE64DATA!
   *
   * @param {string} cipher the ciphertext to decrypt
   *
   * @return resolves to the binary decrypted data as Uint8Array
   */
  async decryptToBuffer(cipher: string): Promise<Uint8Array> {
    const cipherInfo = extractCipherInfo(cipher)
    if (!cipherInfo) {
      return Promise.reject(new Error('NO_CIPHERINFO_FOUND'))
    }
    switch (cipherInfo.algorithm) {
      case CipherAlgorithm.JSON:
        return Promise.resolve(cipherInfo.cipher)
      case CipherAlgorithm.AES:
        return this.decryptAEStoBuffer(cipherInfo)
      case CipherAlgorithm.RSA:
        return this.decryptRSAToBuffer(cipherInfo)
    }
  }

  private async decryptAEStoBuffer(
    cipherInfo: Pick<CipherInfo, 'keyId' | 'cipher' | 'version'>
  ): Promise<Uint8Array> {
    const key = await this.getEncryptionKey(cipherInfo.keyId)

    if (!key) {
      return Promise.reject(['AES_KEY_MISSING', cipherInfo.keyId])
    }

    switch (cipherInfo.version) {
      case CipherVersion.VER_1:
        if (this.decryptWithWebCrypto && key.webCrypto) {
          return subtleSymmetricDecryptOld(cipherInfo.cipher, key.webCrypto)
        }
        return symmetric.decryptOldToBuffer(createBuffer(cipherInfo.cipher), key.plain)
      default:
        if (this.decryptWithWebCrypto && key.webCrypto) {
          return subtleSymmetricDecrypt(cipherInfo.cipher, key.webCrypto)
        }
        return symmetric.decryptToBuffer(createBuffer(cipherInfo.cipher), key.plain)
    }
  }

  private async decryptRSAToBuffer(
    cipherInfo: Pick<CipherInfo, 'keyId' | 'cipher' | 'version'>
  ): Promise<Uint8Array> {
    const key = await this.getKeyPair(cipherInfo.keyId)
    if (!key) {
      return Promise.reject(['RSA_KEY_MISSING', cipherInfo.keyId])
    }
    const asymmetricData = createBuffer(cipherInfo.cipher)

    switch (cipherInfo.version) {
      case CipherVersion.VER_1:
        return asymmetric.decryptOldToBuffer(asymmetricData, key, this.decryptWithWebCrypto)
      default:
        return asymmetric.decryptToBuffer(asymmetricData, key, this.decryptWithWebCrypto)
    }
  }

  /**
   * decryptStreamToBinary decrypts a stream of ciphertext chunks to a ReadableStream
   *
   * chunks must be in the form ALGO:KEYID:Base64encodeddata!;ALGO:KEYID:Base64encodeddata!;AE...
   *
   * @param {ReadableStream} readableStream that returns the chunks (e.g. fetch returns a stream)
   *
   * @return returns another ReadableStream that can e.g. be piped to a fileStream from streamSaver
   */
  decryptStreamToBinary(readableStream: ReadableStream<string>): ReadableStream<Uint8Array> {
    const cryptor = this
    const decryptor = {
      onChunk: (chunk: Uint8Array) => {},
      onClose: () => {},
    }

    const decryptedStream = new ReadableStream<Uint8Array>(
      {
        start(controller) {
          decryptor.onChunk = (chunk) => controller.enqueue(chunk)
          decryptor.onClose = () => controller.close()
        },
      },
      QUEUING_STRATEGY
    )

    let prevChunk = ''

    const decryptStream = new WritableStream<string>(
      {
        async write(chunk) {
          const parts: string[] = (prevChunk + chunk).split(';')
          const ciphers = parts.slice(0, -1)
          prevChunk = parts[parts.length - 1]

          for (const cipher of ciphers) {
            const decrypted = await cryptor.decryptToBuffer(cipher)
            decryptor.onChunk(decrypted)
          }
        },
        async close() {
          if (prevChunk !== '') {
            const decrypted = await cryptor.decryptToBuffer(prevChunk)
            decryptor.onChunk(decrypted)
          }
          decryptor.onClose()
        },
      },
      QUEUING_STRATEGY
    )

    readableStream.pipeTo(decryptStream)

    return decryptedStream
  }

  /**
   * decrypt decrypts a ciphertext to a UTF8 string
   *
   * cipertext must be in the form ALGO:KEYID:BASE64DATA!
   *
   * @param {string} cipher the ciphertext to decrypt
   *
   * @return <Promise<string>> resolves to the decrypted UTF8 string
   */
  decrypt(cipher: any) {
    return this.decryptToBinary(cipher).then((bytes: string) => decodeUtf8(bytes))
  }

  /**
   * symmetricEncrypt encrypts a plaintext symmetrically with the given `encryptionKey`
   *
   * @param {string} plainText the plaintext to encrypt (utf8)
   * @param {string} keyId the `encryptionKey` to use for encryption
   *
   * @returns <Promise<string>> resolves to the symmetrically encrypted data in form AES2:KEYID:BASE64DATA
   */
  symmetricEncrypt(plainText: string | ByteBuffer, keyId: string | number) {
    const key = this._encryptionKeys[keyId]

    if (!key) {
      return Promise.reject(new Error('NO_SUCH_KEY'))
    }

    // If the user passes a string, treat it as utf-8. To encrypt binary data, pass
    // createBuffer(data, 'raw') as the data argument
    if (typeof plainText === 'string') {
      plainText = createBuffer(plainText, 'utf8')
    }

    if (this.encryptWithWebCrypto && key.webCrypto) {
      return subtleSymmetricEncrypt(byteBufferToArrayBuffer(plainText), key.webCrypto).then(
        (cipher) => 'AES2:' + keyId + ':' + encode64(cipher)
      )
    } else {
      return symmetric
        .encrypt(plainText, key.plain)
        .then((cipher) => 'AES2:' + keyId + ':' + forgeEncode64(cipher.getBytes()))
    }
  }

  /**
   * symmetricEncryptBuffer encrypts a plaintext symmetrically with the given `encryptionKey`
   *
   * @param {string} plainText the plaintext to encrypt (utf8)
   * @param {string} keyId the `encryptionKey` to use for encryption
   *
   * @returns <Promise<string>> resolves to the symmetrically encrypted data in form AES2:KEYID:BASE64DATA
   */
  symmetricEncryptBuffer(plainText: Uint8Array, keyId: string | number): Promise<string> {
    const key = this._encryptionKeys[keyId]

    if (!key) {
      return Promise.reject(new Error('NO_SUCH_KEY'))
    }

    if (this.encryptWithWebCrypto && key.webCrypto) {
      return subtleSymmetricEncrypt(plainText, key.webCrypto).then(
        (cipher) => 'AES2:' + keyId + ':' + encode64(cipher)
      )
    } else {
      return symmetric
        .encrypt(createBuffer(plainText, 'raw'), key.plain)
        .then((cipher) => 'AES2:' + keyId + ':' + forgeEncode64(cipher.getBytes()))
    }
  }

  /**
   * createSymmetricEncryptStream creates a stream to encrypt a file symmetrically with the given `encryptionKey`
   *
   * @param keyId the `encryptionKey` to use for encryption
   * @param onEncryptedChunk callback that e.g. stores the encrypted chunks on a server
   *
   * @returns <WritableStream> that encrypts in chunks, this can then also be `abort`ed
   */
  createSymmetricEncryptStream(
    keyId: string,
    onEncryptedChunk: (chunkSize: number, encryptedChunk: string) => Promise<void>
  ): WritableStream<Uint8Array> {
    const cryptor = this
    return new WritableStream<Uint8Array>(
      {
        async write(chunk) {
          const encryptedChunk = await cryptor.symmetricEncryptBuffer(chunk, keyId)
          await onEncryptedChunk(chunk.length, encryptedChunk + ';')
        },
        abort(err) {
          console.log('Error:', err)
        },
      },
      QUEUING_STRATEGY
    )
  }

  asymmetricEncryptBuffer(
    plaintext: Uint8Array,
    keyPairId?: string,
    e?: string,
    n?: string
  ): Promise<string> {
    return this.asymmetricEncrypt(createBuffer(plaintext, 'raw'), keyPairId, e, n)
  }

  /**
   * asymmetricEncrypt encrypts a plaintext asymmetrically with the given public key
   *
   * @param {string} plainText the plaintext to encrypt (utf8)
   * @param <string|undefined> keyPairId when this is a string, it adds "RSA2:<keyPairId>:" in front of the result
   * @param {string} e the public exponent (Base64 encoded)
   * @param {string} n the modulus (Base64 encoded)
   *
   * @returns <Promise<string>> resolves to the encrypted key data
   */
  asymmetricEncrypt(data: ByteBuffer, keyPairId?: string, e?: string, n?: string) {
    let pkey
    if (n && e) {
      const eBn = base64ToBigNum(e)
      const nBn = base64ToBigNum(n)
      pkey = setPublicKey(nBn, eBn)
    } else {
      pkey = this._userKeyPair.publicKey
    }

    // If the user passes a string, treat it as utf-8. To encrypt binary data, pass
    // createBuffer(data, 'raw') as the data argument
    if (typeof data.bytes !== 'function') {
      data = createBuffer(data, 'utf8')
    }

    return asymmetric.encrypt(data, { publicKey: pkey }).then((cipher) => {
      let output: string = forgeEncode64(cipher.bytes())

      if (keyPairId) {
        output = 'RSA2:' + keyPairId + ':' + output
      }
      return output
    })
  }

  /**
   * hasEncryptionKey checks for the existance of an `encryptionKey`
   *
   * @param {string} keyId the key id to check for
   *
   * @returns <boolean>
   */
  hasEncryptionKey(keyId: string | number) {
    return !!this._encryptionKeys[keyId]
  }

  _loadKeyPair(
    eB64: string,
    nB64: string,
    dB64: string,
    pB64?: string,
    qB64?: string,
    dpB64?: string,
    dqB64?: string,
    qinvB64?: string
  ) {
    return Promise.all([
      base64ToBigNum(eB64),
      base64ToBigNum(nB64),
      this._decryptBignum(dB64),
      pB64 ? this._decryptBignum(pB64) : undefined,
      qB64 ? this._decryptBignum(qB64) : undefined,
      dpB64 ? this._decryptBignum(dpB64) : undefined,
      dqB64 ? this._decryptBignum(dqB64) : undefined,
      qinvB64 ? this._decryptBignum(qinvB64) : undefined,
    ]).then(([e, n, d, p, q, dp, dq, qinv]) => {
      return {
        privateKey: setPrivateKey(n, e, d, p, q, dp, dq, qinv),
        publicKey: setPublicKey(n, e),
      }
    })
  }

  private _decryptBignum(encryptedBignum: string) {
    if (extractCipherInfo(encryptedBignum)) {
      return this.decryptToBuffer(encryptedBignum).then(bytesToBigNum)
    }

    const dBytes = forgeDecode64(encryptedBignum)
    return symmetric
      .decryptToBuffer(createBuffer(dBytes), this.masterKey as Uint8Array)
      .catch((err: { message: string; toString: () => void }) => {
        if (err.message === 'HMAC_MISMATCH') {
          return symmetric
            .decryptOldToBuffer(createBuffer(dBytes), this.masterKey as Uint8Array)
            .catch((err: any) => {
              console.error('Error decrypting private key:', err)
              throw err
            })
        } else {
          console.log(err.toString())
          throw new Error('Could not decrypt private exponent: ' + err.message)
        }
      })
      .then(bytesToBigNum)
  }
}
