const searchForms = document.querySelectorAll('.js-search-form')

export function clearLocationSearchFilters() {
  const clearEvent = new Event('clearSelectValues')
  document.dispatchEvent(clearEvent)

  searchForms.forEach((searchForm) => {
    const nearFieldInput = searchForm.querySelector('input[name="near"]')
    const searchFieldMobile = document.querySelector('.c-search-form-sm__searched-field')
    const searchLocationMobile = document.querySelector('.c-search-form-sm__searched-location')

    nearFieldInput.value = ''
    searchFieldMobile.innerText = ''
    searchLocationMobile.innerText = ''
  })
}
