/**
 * Returns the page's CSRF token from the meta tag (this is a Rails thing)
 *
 * When doing XHR POST, PUT and DELETE, you must provide this.
 *
 * The meta tag is rendered by `csrf_meta_tags` in the layout
 */
export function authenticityToken(doc = document) {
  const token = doc.querySelector('meta[name="csrf-token"]')

  if (token) {
    return (token as HTMLMetaElement).content
  }
  return null
}

/**
 * Returns HTTP headers including the CSRF token
 *
 * Example:
 *
 * axios.post(url, data, { headers: authenticityHeaders() })
 */
export function authenticityHeaders(otherHeaders: { [id: string]: string } = {}, doc = document) {
  return Object.assign(otherHeaders, {
    'X-CSRF-Token': authenticityToken(doc),
    'X-Requested-With': 'XMLHttpRequest'
  })
}
