import cx from 'classnames'
import React from 'react'

interface Props {
  children: React.ReactNode
  className?: string
  duration?: number
}
export const SuccessToast: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <div className={cx('fixed w-full px-8', className)}>
      <div className='mx-auto max-w-[300px] rounded bg-success-600 px-4 py-2 text-center text-white shadow-sm'>
        {children}
      </div>
    </div>
  )
}
