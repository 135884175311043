import { ByteBuffer, bytesToHex } from 'node-forge/lib/util'

import { generateKey as pkcs12GenerateKey } from 'node-forge/lib/pkcs12'
import sha256 from 'node-forge/lib/sha256'
import { generate as randomGenerateNode } from 'node-forge/lib/random'
import { byteBufferToArrayBuffer } from './byteBufferToArrayBuffer'

export function randomGenerate(size: number): Promise<string> {
  return new Promise((resolve, reject) => {
    randomGenerateNode(size, (err, result) => {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })
  })
}
export function generateMasterkey(password: string, salt: string): Uint8Array {
  salt = salt.toLowerCase()

  /* Convert salt to Unicode byte buffer + trailing 0-byte. */
  const saltBuf = new ByteBuffer()
  for (let l = 0; l < salt.length; l++) {
    saltBuf.putInt16(salt.charCodeAt(l))
  }
  saltBuf.putInt16(0)

  const bytes = pkcs12GenerateKey(password, saltBuf, 1, 2048, 256 / 8, sha256.create())

  return byteBufferToArrayBuffer(bytes)
}

export function generatePWClientHash(username: string, password: string): string {
  const md = sha256.create()
  md.update(username.toLowerCase())
  md.update('-')
  md.update(password)
  md.update('-client.samedi.de')
  const dgst = md.digest()

  return bytesToHex(dgst.getBytes())
}

/**
 * Converts a binary string of raw bytes to Uint8Array (NO charachter encoding)
 *
 * Use this function to convert binary strings to binary data (NOT text! this does not handle utf8 strings!)
 */
export function str2ab(str: string): Uint8Array {
  const bytes = new Uint8Array(str.length)
  for (let iii = 0; iii < str.length; iii++) {
    bytes[iii] = str.charCodeAt(iii)
  }

  return bytes
}

/**
 * Converts a Uint8Array of raw bytes to a raw character string (NO charachter encoding)
 *
 * Use this function to convert binary data to binary strings (NOT text! this does not handle utf8 strings!)
 */
export function ab2str(buf: ArrayBuffer | Uint8Array): string {
  let result = ''
  if (buf) {
    const bytes = new Uint8Array(buf)
    for (let i = 0; i < bytes.byteLength; i++) {
      result = result + String.fromCharCode(bytes[i])
    }
  }
  return result
}

/**
 * Encodes a UTF8 string to its raw bytes
 *
 * Use this for text, not binary data
 *
 * @param str utf8 string
 */
export function utf8StringToBytes(str: string): Uint8Array {
  const encoder = new TextEncoder()
  return encoder.encode(str)
}

/**
 * Decodes a utf8 byte buffer to its proper text representation
 *
 * Use this for text, not binary data
 *
 * @param buf utf8 buffer
 */
export function bytesToUtf8String(buf: Uint8Array) {
  const decoder = new TextDecoder('utf-8')
  return decoder.decode(buf)
}

// from http://stackoverflow.com/a/5396742/69934
export function decodeUtf8(bytes: string): string {
  try {
    // If the string is ISO 8859-1, this will work and not throw an error.
    return decodeURIComponent(escape(bytes))
  } catch (e) {
    // the string is already UTF-8, leave it!
    return bytes
  }
}
