import './style.css'

// Todo: make dropdowns more accessible
function closeAllDropdowns() {
  const openedDropdowns = document.querySelectorAll('.js-dropdown.is-opened')
  openedDropdowns.forEach((dropdown) => dropdown.classList.remove('is-opened'))
}

const dropdownTriggers = document.querySelectorAll('.js-dropdown__switch')
dropdownTriggers.forEach((element) => {
  element.addEventListener('click', (e) => {
    e.preventDefault()
    e.stopPropagation()
    let dropdownParent = element.parentElement
    while (!dropdownParent.classList.contains('js-dropdown')) {
      dropdownParent = dropdownParent.parentElement
    }
    if (dropdownParent.classList.contains('is-opened')) {
      dropdownParent.classList.remove('is-opened')
    } else {
      closeAllDropdowns()
      dropdownParent.classList.add('is-opened')
    }
  })
})

document.addEventListener('click', closeAllDropdowns)
