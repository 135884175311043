import { useState } from 'react'

import { getCharacterArrayFromString, getSafeArrayIndex, CODE_INPUT_LENGTH } from './utils'

const useCodeInput = (value: string, onChange: (value: string) => void) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const characterArray = getCharacterArrayFromString(value)

  const reset = () => {
    setActiveIndex(0)
  }

  const goToNextIndex = () => {
    const nextIndex = getSafeArrayIndex(activeIndex + 1)
    setActiveIndex(nextIndex)
  }

  const goToPrevIndex = () => {
    const prevIndex = getSafeArrayIndex(activeIndex - 1)
    setActiveIndex(prevIndex)
  }

  const updateCharAt = (index: number, text: string) =>
    onChange(
      characterArray
        .map((char, i) => (i === index ? text : char))
        .join('')
        .substring(0, CODE_INPUT_LENGTH)
        .trimEnd()
    )

  const handlePaste = (text: string) => {
    if (!text) {
      return
    }

    const prefix = value.slice(0, activeIndex)
    let replacedFragment = ''
    let suffix = ''

    if (text.length + activeIndex >= CODE_INPUT_LENGTH) {
      replacedFragment = text.slice(0, CODE_INPUT_LENGTH - prefix.length)
    } else {
      replacedFragment = text
      suffix = value.slice(activeIndex + text.length)
    }
    onChange([prefix, replacedFragment, suffix].join(''))
    setActiveIndex(activeIndex + replacedFragment.length)
  }

  const handleChange = (text: string) => {
    if (text === '') return

    updateCharAt(activeIndex, text.slice(-1))
    goToNextIndex()
  }

  const handleBackspacePress = () => {
    updateCharAt(activeIndex, '')
    goToPrevIndex()
  }

  const handleFocus = (index: number) => () => setActiveIndex(getSafeArrayIndex(index))

  return {
    activeIndex,
    reset,
    characterArray,
    goToNextIndex,
    goToPrevIndex,
    handleChange,
    handleBackspacePress,
    handleFocus,
    handlePaste
  }
}

export default useCodeInput
