/* eslint-disable no-undef */

import URLSearchParams from '@ungap/url-search-params'
import axios from 'axios'

import { mapMarkersChangedEvent } from './customEvents'

const loadMoreButton = document.querySelector('.js-search-results__load-more')
const resultsContainer = document.querySelector('.c-search-results__practices')
const noMoreResultsText = document.querySelector('.c-search-results-page__no-more-results')
const mapElement = document.querySelector('#map')
const searchForm = document.querySelector('.js-search-form')

let page = 1

if (loadMoreButton && resultsContainer) {
  loadMoreButton.addEventListener('click', (_evt) => {
    page++
    loadMoreButton.classList.add('is-active')

    axios
      .get(`searches/${page}?${new URLSearchParams(new FormData(searchForm)).toString()}`)
      .then((response) => {
        const { isLastPage, mapMarkers, practices } = response.data

        loadMoreButton.classList.remove('is-active')
        if (isLastPage || !practices.length) {
          loadMoreButton.classList.add('is-hidden')
        }

        if (isLastPage) {
          noMoreResultsText.classList.remove('is-hidden')
        }

        resultsContainer.insertAdjacentHTML('beforeend', practices.join(''))

        const oldMarkers = JSON.parse(mapElement.getAttribute('data-markers'))
        const allCurrentMarkers = oldMarkers.concat(JSON.parse(mapMarkers))
        mapElement.setAttribute('data-markers', JSON.stringify(allCurrentMarkers))
        mapElement.dispatchEvent(mapMarkersChangedEvent)

        const event = document.createEvent('Event')
        event.initEvent('change', true, true)
        document.body.dispatchEvent(event)
      })
      .finally(() => loadMoreButton.classList.remove('is-active'))
  })
}
