import { fromByteArray, toByteArray } from 'base64-js'

export const IVSize = 32

// remove all whitespace from a string
const removeWhitespace = (s: string): string => s.replace(/\s+/g, '')

// Create a new key pair that can be used for signing and signature verification.
export function generateSigningKeyPair(): Promise<CryptoKeyPair> {
  return crypto.subtle.generateKey(
    {
      name: 'RSA-PSS',
      hash: { name: 'SHA-256' },
      modulusLength: 4096,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
    },
    true,
    ['sign', 'verify']
  )
}

// Converts the master key from cryptor into an AES-GCM key that can be used by crypto-subtle to wrap/unwrap key pairs.
export function convertMasterKey(cryptorMasterKey: Uint8Array): Promise<CryptoKey> {
  return crypto.subtle.importKey('raw', cryptorMasterKey, { name: 'AES-GCM' }, false, [
    'wrapKey',
    'unwrapKey',
  ])
}

// Exports an RSA private key in jwk encoded form.
// The private key is encrypted with masterKey using AES-GCM with a 32 byte IV prepended.
export async function exportSigningPrivateKey(
  masterKey: CryptoKey,
  keyPair: CryptoKey
): Promise<string> {
  const iv = crypto.getRandomValues(new Uint8Array(IVSize))
  const wrappedKey: ArrayBuffer = await crypto.subtle.wrapKey('jwk', keyPair, masterKey, {
    name: 'AES-GCM',
    iv,
  })

  const wrappedKeyWithIV = new Uint8Array(wrappedKey.byteLength + iv.byteLength)
  wrappedKeyWithIV.set(iv, 0)
  wrappedKeyWithIV.set(new Uint8Array(wrappedKey), iv.byteLength)

  const encodedWrappedKey = fromByteArray(wrappedKeyWithIV)
  return encodedWrappedKey
}

// Imports an RSA private key in jwk encoded form that was encrypted with masterKey using AES-CGM. The first 32 bytes are the IV.
export function importSigningPrivateKey(
  masterKey: CryptoKey,
  privateKey: string
): Promise<CryptoKey> {
  const decodedWrappedKeyWithIV: Uint8Array = toByteArray(removeWhitespace(privateKey))
  const buffer = decodedWrappedKeyWithIV.buffer
  const iv = new Uint8Array(buffer, 0, IVSize)
  const decodedWrappedKey = new Uint8Array(buffer, IVSize)

  return crypto.subtle.unwrapKey(
    'jwk',
    decodedWrappedKey,
    masterKey,
    { name: 'AES-GCM', iv: iv },
    {
      name: 'RSA-PSS',
      hash: { name: 'SHA-256' },
    },
    true,
    ['sign']
  )
}

// Export an RSA public key into a string in JWK encoded form.
export async function exportSigningPublicKey(key: CryptoKey) {
  const exported = await crypto.subtle.exportKey('jwk', key)
  return JSON.stringify(exported)
}

// Import a JWK encoded RSA public key, to use for RSA-PSS signature verification.
export function importSigningPublicKey(jwk: string): Promise<CryptoKey> {
  return window.crypto.subtle.importKey(
    'jwk',
    JSON.parse(jwk),
    {
      name: 'RSA-PSS',
      hash: 'SHA-256',
    },
    true,
    ['verify']
  )
}
