/* eslint-env browser */
import { createBuffer, ByteBuffer } from 'node-forge/lib/util'
import sha256 from 'node-forge/lib/sha256'
import aes from 'node-forge/lib/aes'
import forgeHmac from 'node-forge/lib/hmac'

import { randomGenerate, str2ab, ab2str } from './utils'
import { HMAC_SIGNATURE_LENGTH, IV_LENGTH } from './constants'

const decryptAES = function decryptAESForge(
  key: ByteBuffer,
  iv: string,
  ciphertext: string
): ByteBuffer {
  const cipher = aes.startDecrypting(key, iv)
  cipher.update(createBuffer(ciphertext))
  cipher.finish()

  return cipher.output
}

function bufferToUint8Array(buffer: ByteBuffer): Uint8Array {
  const l = buffer.length()
  const typedArray = new Uint8Array(buffer.length())

  for (let i = 0; i < l; ++i) {
    typedArray[i] = buffer.at(i) & 0xff
  }

  return typedArray
}

function bufferToString(buffer: ByteBuffer): string {
  try {
    return buffer.toString()
  } catch (e) {
    return buffer.bytes()
  }
}

const AES = 1
const HMAC = 2

const generateKey = function (key: Uint8Array, type: 1 | 2): ByteBuffer {
  const md = sha256.create()
  md.update(ab2str(key))

  switch (type) {
    case AES:
      md.update('1')
      break
    case HMAC:
      md.update('2')
      break
  }

  return md.digest()
}

export default {
  encrypt: function (plaintext: ByteBuffer, key: Uint8Array): Promise<ByteBuffer> {
    return randomGenerate(IV_LENGTH).then((iv) => {
      const keyAes = generateKey(key, AES)
      const keyHmac = generateKey(key, HMAC)
      const cipher = aes.startEncrypting(keyAes, iv)

      cipher.update(plaintext)
      cipher.finish()

      const hmac = forgeHmac.create()
      hmac.start(sha256.create(), keyHmac)

      hmac.update(iv)
      hmac.update(cipher.output.bytes())

      const output = createBuffer()
      output.putBuffer(hmac.getMac())
      output.putBytes(iv)
      output.putBuffer(cipher.output)

      return output
    })
  },

  decryptToBuffer: function (ciphertext: ByteBuffer, key: Uint8Array): Promise<Uint8Array> {
    const keyAes = generateKey(key, AES)
    const keyHmac = generateKey(key, HMAC)

    const hmac1 = ciphertext.getBytes(HMAC_SIGNATURE_LENGTH)
    const iv = ciphertext.getBytes(IV_LENGTH)
    const ct = ciphertext.getBytes()

    const hmac = forgeHmac.create()
    hmac.start(sha256.create(), keyHmac)
    hmac.update(iv)
    hmac.update(ct)

    const hmac2 = hmac.getMac().bytes()

    if (hmac1 !== hmac2) {
      return Promise.reject(new Error('HMAC_MISMATCH'))
    }

    return Promise.resolve(bufferToUint8Array(decryptAES(keyAes, iv, ct)))
  },

  decryptOldToBuffer: function (ciphertext: ByteBuffer, key: Uint8Array): Promise<Uint8Array> {
    const iv = createBuffer()
    iv.fillWithByte(0, IV_LENGTH)
    const cipher = aes.startDecrypting(createBuffer(key), iv)
    cipher.update(ciphertext)
    cipher.finish()

    // now, output contains: 16B IV, nB data, 32B SHA256
    const outlen = cipher.output.length() - IV_LENGTH - 32

    if (outlen < 0) {
      return Promise.reject(
        new Error(`Corrupt ciphertext (output length was ${outlen} but this is too short!)`)
      )
    }

    cipher.output.getBytes(IV_LENGTH) // drop IV
    const plaintext: string = cipher.output.getBytes(outlen)
    const storedDgst: string = cipher.output.getBytes(32)

    const md = sha256.create()
    md.update(plaintext)
    const dgst = md.digest()

    if (dgst.bytes() !== storedDgst) {
      return Promise.reject(new Error('Corrupt digest!'))
    }

    return Promise.resolve(str2ab(plaintext))
  },
}
