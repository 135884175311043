import Axios, { AxiosError } from 'axios'
import { useState } from 'react'

import { CryptorService } from 'components/features/Cryptor/models/CryptorService'
import fetchEncryptionKeys, {
  EncryptionKeysData
} from 'components/features/Cryptor/services/fetchEncryptionKeys'

// types
export type LoginFormValues = {
  login: {
    email: string
    password: string
    remember: boolean
    otp_token?: string
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OnRejected<T = any> = (error: AxiosError<T>) => any

// hooks
const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false)

  const startLoading = () => setIsLoading(true)
  const stopLoading = () => setIsLoading(false)

  const login = (values: LoginFormValues, onRejected?: OnRejected) => {
    const redirectSuccessUrl = '/'
    const loginClient = Axios.create()

    loginClient.interceptors.request.use((config) => {
      startLoading()
      return config
    })

    loginClient.interceptors.response.use(async (response) => {
      const { status, data } = response
      if (status === 200) {
        data.medical_record_salt &&
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          (await setupCryptoSession(data.medical_record_salt, values.login.password))

        window.location.href = data.return_to ?? redirectSuccessUrl
      }
      return response
    }, onRejected)

    return loginClient.post('/login.json', values).finally(stopLoading)
  }

  return { login, isLoading }
}

// utils
const setupCryptoSession = async (salt: string, password: string) => {
  try {
    const { keyPair, encryptionKeys }: EncryptionKeysData = await fetchEncryptionKeys()
    const cryptor = new CryptorService(salt, keyPair, encryptionKeys[0])
    await cryptor.setUpFromPassword(password)
  } catch (e) {
    console.log('cryptor error', e)
  }
}

export default useLogin
