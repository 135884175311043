import { EncryptedKeyPair } from '@samedi/crypto-js/cryptor'
import { EncryptedSigningKey } from '@samedi/crypto-js/signer'
import axios from 'axios'

import { EncryptionKey } from '../models/CryptorService'

export interface EncryptionKeysData {
  keyPair?: EncryptedKeyPair
  encryptionKeys: Array<EncryptionKey>
  signingKey?: EncryptedSigningKey
}

interface EncryptionKeyResponse {
  key_pair?: EncryptedKeyPair
  encryption_keys: Array<EncryptionKey>
  signing_key: { key_id: string; private_key_encrypted: string } | null
}

export default async function fetchEncryptionKeys(): Promise<EncryptionKeysData> {
  const response = await axios.get<EncryptionKeyResponse>('/encryption_keys.json')
  const { key_pair: keyPair, encryption_keys: encryptionKeys, signing_key } = response.data

  return {
    keyPair,
    encryptionKeys,
    signingKey: signing_key
      ? {
          keyId: signing_key.key_id,
          encryptedPrivateKey: signing_key.private_key_encrypted
        }
      : undefined
  }
}
