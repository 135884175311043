import { createPopper } from '@popperjs/core'

initializeTooltips()
document.body.addEventListener('change', initializeTooltips)

function initializeTooltips() {
  const tooltips = document.querySelectorAll('.js-tooltip:not(.js-tooltip--initialized)')
  tooltips.forEach((tooltip) => {
    tooltip.classList.add('js-tooltip--initialized')
    const trigger = tooltip.querySelector('.js-tooltip__trigger')
    const anchor = tooltip.querySelector('.js-tooltip__anchor') || trigger
    const content = tooltip.querySelector('.js-tooltip__content')

    const popperInstance = createPopper(anchor, content, {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 12]
          }
        }
      ]
    })

    content.style.display = 'none'
    let isVisible = false

    trigger.addEventListener('click', () => {
      isVisible = !isVisible
      if (isVisible) {
        content.style.display = 'block'
        popperInstance.update()
      } else {
        content.style.display = 'none'
      }
    })
  })
}
