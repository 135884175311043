import {
  CompactSign,
  compactVerify,
  CompactVerifyGetKey,
  CompactVerifyResult,
  ResolvedKey,
} from 'jose'

export async function sha256digest(data: string): Promise<ArrayBuffer> {
  const buffer = new TextEncoder().encode(data)
  return await crypto.subtle.digest('SHA-256', buffer)
}

/** Creates a JWS signature string (compact form) from dataToSign using RSA-PSS algorithm with SHA-256 hash.
 * The signature contains key ID, signer name and the signing timestamp as metadata in the header.
 *
 * @param keyId ID of the key being used to sign the data.
 * @param issuerName The human readable name of the person signing.
 * @param signedAt the timestamp to use for signing. If this is not passed, the current time will be used instead.
 */
export async function signBuffer(
  signingKey: CryptoKey,
  dataToSign: ArrayBuffer,
  keyId: string,
  issuerName: string,
  signedAt?: Date
): Promise<string> {
  const unixMilliseconds = signedAt ? +signedAt : Date.now()
  const iat = Math.floor(unixMilliseconds / 1000)

  return await new CompactSign(new Uint8Array(dataToSign))
    .setProtectedHeader({ alg: 'PS256', kid: keyId, iss: issuerName, iat: iat })
    .sign(signingKey)
}

// Verifies a JWS signature string and returns the signed data and header.
export async function verifyBuffer(
  jws: string,
  key: CompactVerifyGetKey
): Promise<CompactVerifyResult & ResolvedKey> {
  return compactVerify(jws, key, { algorithms: ['PS256'] })
}
