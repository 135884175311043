/**
 * Creates a new hidden field and appends it to form
 */
export default function addHiddenFieldToForm(name: string, value: string, form: HTMLFormElement) {
  if (!name || !name.length) throw new Error('no name specified for hidden field')
  if (!value || !value.length) throw new Error('no value specified for hidden field')
  if (!form) throw new Error('no form specified for hidden field')

  const newHiddenField = document.createElement('input')
  newHiddenField.setAttribute('type', 'hidden')
  newHiddenField.setAttribute('name', name)
  newHiddenField.setAttribute('value', value)
  form.appendChild(newHiddenField)
}
