/**
 * This is our icon pack which includes all fontawesome icons we're including via DOM / JS
 * e.g. <i class="fal fa-user"></i>
 *
 * In order to use such an icon, you must import it from the proper FA package and add it to the library
 *
 * This way, we can use all FA icons (~6000 right now) without downloading them all to the
 * user's browser, we're only downloading what we need.
 */

import { dom, library } from '@fortawesome/fontawesome-svg-core'
// import regular icons
import {
  faAndroid,
  faApple,
  faChrome,
  faEdge,
  faFacebookSquare,
  faFirefox
} from '@fortawesome/free-brands-svg-icons'
import {
  faAlarmClock,
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowUp,
  faArrowsRotate,
  faBars,
  faCalendarDay,
  faCalendarDays,
  faCalendarPen,
  faCalendarPlus,
  faCalendarXmark,
  faCaravanSimple,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleInfo,
  faClipboardList,
  faClock,
  faClockRotateLeft,
  faComments,
  faCopy,
  faCubesStacked,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFax,
  faFilesMedical,
  faFloppyDisk,
  faGlobe,
  faHouseUser,
  faIdCard,
  faInhaler,
  faKitMedical,
  faListUl,
  faLocationDot,
  faLockAlt,
  faMagnifyingGlass,
  faMapLocation,
  faMobile,
  faNewspaper,
  faPaperclip,
  faPersonWalkingArrowRight,
  faPhone,
  faPhoneLaptop,
  faPlus,
  faPlusCircle,
  faRssSquare,
  faShieldCheck,
  faTrashCan,
  faUserCrown,
  faUserDoctor,
  faUserGear,
  faUserGroupCrown,
  faUserPlus,
  faUserShield,
  faUtensils,
  faVideo,
  faVideoSlash,
  faXRay,
  faXmark
} from '@fortawesome/pro-regular-svg-icons'
// Import brand icons

// import solid icons
import { faCircleSmall, faCircleUser } from '@fortawesome/pro-solid-svg-icons'

// Add icons to library
library.add(
  faAlarmClock,
  faAndroid,
  faAngleDown,
  faAngleUp,
  faApple,
  faArrowDown,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowUp,
  faArrowsRotate,
  faBars,
  faCalendarDay,
  faCalendarDays,
  faCalendarPen,
  faCalendarPlus,
  faCalendarXmark,
  faCaravanSimple,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChrome,
  faCircleInfo,
  faCircleSmall,
  faCircleUser,
  faClipboardList,
  faClock,
  faClockRotateLeft,
  faComments,
  faCopy,
  faCubesStacked,
  faEdge,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFacebookSquare,
  faFax,
  faFilesMedical,
  faFirefox,
  faFloppyDisk,
  faGlobe,
  faHouseUser,
  faIdCard,
  faInhaler,
  faKitMedical,
  faListUl,
  faLocationDot,
  faLockAlt,
  faMagnifyingGlass,
  faMapLocation,
  faMobile,
  faNewspaper,
  faPaperclip,
  faPersonWalkingArrowRight,
  faPhone,
  faPhoneLaptop,
  faPlus,
  faPlusCircle,
  faRssSquare,
  faShieldCheck,
  faTrashCan,
  faUserCrown,
  faUserDoctor,
  faUserGear,
  faUserGroupCrown,
  faUserPlus,
  faUserShield,
  faUtensils,
  faVideo,
  faVideoSlash,
  faXRay,
  faXmark
)

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch()
