import { t } from 'i18n'

import { FormProps, FormValues } from './types'

export const CODE_INPUT_LENGTH = 6

export const getCharacterArrayFromString = (value: string) =>
  Array(CODE_INPUT_LENGTH)
    .fill('')
    .map((_, i) => value.charAt(i))

export const getSafeArrayIndex = (index: number) => {
  if (index < 0) {
    return 0
  }

  if (index > CODE_INPUT_LENGTH - 1) {
    return CODE_INPUT_LENGTH - 1
  }

  return index
}

export const validate = (values: FormValues) => {
  const errors: Partial<FormValues> = {}

  const codeLength = values.code.length

  if (codeLength === 0) {
    errors.code = t('login.verify-code.error-no-input')
  }

  if (codeLength > 0 && codeLength < CODE_INPUT_LENGTH) {
    errors.code = t('login.verify-code.error-digits-missing')
  }

  return errors
}

export const getIsError = (form: FormProps<FormValues>, isInvalidInput: boolean) => {
  return (form.errors.code && form.touched.code) || isInvalidInput
}
