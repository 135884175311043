/* eslint-env browser */

import { createBuffer, ByteBuffer } from 'node-forge/lib/util'
import asn1 from 'node-forge/lib/asn1'
import { createKeyPairGenerationState, stepKeyPairGenerationState } from 'node-forge/lib/rsa'

import { str2ab, ab2str } from './utils'
import symmetric from './symmetric'
import { getRandomValues } from './subtle/getRandomValues'
import {
  decrypt as subtleSymmetricDecrypt,
  decryptOld as subtleSymmetricDecryptOld,
  loadSessionKey as subtleLoadSessionKey,
} from './subtle/symmetric'

export interface KeyPair {
  publicKey: any
  privateKey?: any
}

const decryptRSA = function (key: KeyPair, ciphertext: string): Promise<Uint8Array> {
  try {
    const output: string = key.privateKey.decrypt(ciphertext, 'RSA-OAEP')
    return Promise.resolve(str2ab(output))
  } catch (err) {
    return Promise.reject(err)
  }
}

export default {
  generateKeyPair: function (stepCallback?: () => void, keyLength?: number): Promise<KeyPair> {
    return new Promise((resolve) => {
      const state = createKeyPairGenerationState(keyLength || 2048)
      const step = function () {
        // step key-generation, run algorithm for 100 ms, repeat
        if (!stepKeyPairGenerationState(state, 100)) {
          if (typeof stepCallback === 'function') {
            stepCallback()
          }
          setTimeout(step, 1)
        } else {
          // key-generation complete
          resolve(state.keys)
        }
      }
      setTimeout(step, 0)
    })
  },

  encrypt: function (plaintext: ByteBuffer, key: KeyPair): Promise<ByteBuffer> {
    const sessionKey = getRandomValues(32)

    return Promise.all([
      symmetric.encrypt(plaintext, sessionKey),
      key.publicKey.encrypt(ab2str(sessionKey), 'RSA-OAEP'),
    ]).then(([encryptedData, encryptedSessionKey]) => {
      const skAsn1 = asn1.create(
        asn1.Class.UNIVERSAL,
        asn1.Type.BITSTRING,
        false,
        this.bitStringToDer(encryptedSessionKey).bytes()
      )
      const edAsn1 = asn1.create(
        asn1.Class.UNIVERSAL,
        asn1.Type.BITSTRING,
        false,
        this.bitStringToDer(encryptedData).bytes()
      )

      const seq = asn1.create(asn1.Class.UNIVERSAL, asn1.Type.SEQUENCE, true, [skAsn1, edAsn1])

      return asn1.toDer(seq)
    })
  },

  decryptToBuffer: function (
    ciphertext: ByteBuffer | string,
    key: KeyPair,
    useWebCrypto: boolean = false
  ): Promise<Uint8Array> {
    const seq = asn1.fromDer(ciphertext, {
      strict: true,
      decodeBitStrings: false,
    })

    const encryptedSessionKey = this.derToBitString(seq.value[0].value)
    const encryptedData = this.derToBitString(seq.value[1].value)

    return decryptRSA(key, encryptedSessionKey).then((sessionKey) => {
      if (useWebCrypto) {
        return subtleLoadSessionKey(sessionKey).then((sessionKey) =>
          subtleSymmetricDecrypt(str2ab(encryptedData), sessionKey)
        )
      } else {
        return symmetric.decryptToBuffer(createBuffer(encryptedData), sessionKey)
      }
    })
  },

  decryptOldToBuffer: function (
    ciphertext: ByteBuffer,
    key: KeyPair,
    useWebCrypto: boolean = false
  ): Promise<Uint8Array> {
    const seq = asn1.fromDer(ciphertext, {
      strict: true,
      decodeBitStrings: false,
    })

    const encryptedSessionKey = this.derToBitString(seq.value[0].value)
    const encryptedData = this.derToBitString(seq.value[1].value)
    let sessionKey: Uint8Array = str2ab(key.privateKey.decrypt(encryptedSessionKey, 'NONE'))

    if (sessionKey.byteLength === 256) {
      // the bignum was right-padded with zeros to 256 bytes
      sessionKey = sessionKey.slice(256 - 32)
    }

    if (useWebCrypto) {
      return subtleLoadSessionKey(sessionKey)
        .then((sessionKey) => subtleSymmetricDecryptOld(str2ab(encryptedData), sessionKey))
        .then((plainData) => {
          const ab = plainData.buffer
          const result = new Uint8Array(ab, plainData.byteOffset, plainData.byteLength - 32)

          return result
        })
    } else {
      return symmetric
        .decryptOldToBuffer(createBuffer(encryptedData), sessionKey)
        .then((plainData) => {
          const ab = plainData.buffer

          return new Uint8Array(ab, plainData.byteOffset, plainData.byteLength - 32)
        })
    }
  },

  bitStringToDer: function (bs: ByteBuffer | string): ByteBuffer {
    const bytes: ByteBuffer = createBuffer()

    bytes.putByte(0x00)

    // is it a byte buffer?
    if (typeof (bs as ByteBuffer).bytes === 'function') {
      bs = (bs as ByteBuffer).bytes()
    }

    bytes.putBytes(bs as string)

    return bytes
  },

  derToBitString: function (bytes: string): string {
    // just dump the first byte
    return bytes.substr(1)
  },
}
