/**
 * This module implements our crypto scheme using native crypto.subtle
 *
 * For an overview about crypto.subtle, see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto
 *
 * Supported Browsers:
 * * Chrome 37+
 * * Firefox 34+
 * * Safari 10.1+ (7+ with a shim)
 * * Edge 12+
 * * IE 11 (with a shim)
 *
 * For buggy browsers, we can use https://github.com/vibornoff/webcrypto-shim.
 */
import { HMAC_SIGNATURE_LENGTH, IV_LENGTH, KEY_LENGTH } from '../constants'
import { getRandomValues } from './getRandomValues'

/**
 * Encapsulates a symmetric encryption key with our "internals" for HMAC and AES keys
 */
export interface Key {
  aes: CryptoKey
  hmac: CryptoKey
  // Base key for decrypting data according to old scheme (AES), used by decryptOld()
  // That scheme did not use HMAC and relied on the one, raw key.
  base: CryptoKey
}

/**
 * Loads the symmetric session key
 *
 * Internally, this generates the key material for HMAC and AES, according to our crypto scheme
 *
 * @param key the raw key bytes of the AES key
 */
export function loadSessionKey(key: Uint8Array): Promise<Key> {
  return generateKeys(key)
}

/**
 * Generates a random symmetric encryption key and returns it as a byte array
 */
export function generateSessionKey(): Uint8Array {
  return getRandomValues(KEY_LENGTH)
}

/**
 * Encrypts the given data using AES-CBC and resolves with a Uint8Array
 *
 * @param data data to encrypt
 * @param key the symmetric key to use
 */
export async function encrypt(data: Uint8Array, key: Key): Promise<Uint8Array> {
  const iv = getRandomValues(IV_LENGTH)
  const { hmac, aes } = key
  const payload = await crypto.subtle.encrypt(
    {
      name: 'AES-CBC',
      iv,
    },
    aes,
    data
  )

  // we need a buffer to write the HMAC signature, IV and encrypted payload into
  const resultBuffer = new ArrayBuffer(HMAC_SIGNATURE_LENGTH + IV_LENGTH + payload.byteLength)
  const result = new Uint8Array(resultBuffer)

  // We can already fill IV and payload because we need this to calculate the HMAC
  result.set(iv, HMAC_SIGNATURE_LENGTH)
  result.set(new Uint8Array(payload), HMAC_SIGNATURE_LENGTH + IV_LENGTH)

  const dataToSign = new Uint8Array(resultBuffer, HMAC_SIGNATURE_LENGTH)
  const signature = await crypto.subtle.sign(
    {
      name: 'HMAC',
      hash: 'SHA-256',
    },
    hmac,
    dataToSign
  )
  result.set(new Uint8Array(signature), 0)

  return result
}

/**
 * Decrypts the given data using the passed key and resolved with a Uint8Array
 *
 * @param data data to decrypt
 * @param key the symmetric key to use
 */
export async function decrypt(data: Uint8Array, key: Key): Promise<Uint8Array> {
  const { hmac, aes } = key

  const { signature, iv, payload, signedData } = splitDataIntoParts(data)

  const hmacVerified = await crypto.subtle.verify(
    { name: 'HMAC', hash: 'SHA-256' },
    hmac,
    signature,
    signedData
  )
  if (!hmacVerified) {
    throw new Error('HMAC verify failed!')
  }

  const decryptedData = await crypto.subtle.decrypt({ name: 'AES-CBC', iv: iv }, aes, payload)

  return new Uint8Array(decryptedData)
}

const OLD_IV = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
console.assert(OLD_IV.length == IV_LENGTH)
/**
 * Decrypts data encrypted using our oldest encryption scheme
 *
 * @param data ciphertext to decrypt
 * @param key the key
 */
export async function decryptOld(data: Uint8Array, key: Key): Promise<Uint8Array> {
  const iv = new Uint8Array(OLD_IV)

  const decryptedData = await crypto.subtle.decrypt(
    {
      name: 'AES-CBC',
      iv: iv,
    },
    key.base,
    data
  )
  // now, output contains: 16B IV (random bytes), nB data, 32B SHA256
  const outlen = decryptedData.byteLength - IV_LENGTH - 32

  if (outlen < 0) {
    throw new Error(`Corrupt ciphertext (output length was ${outlen} but this is too short!)`)
  }
  const plaintext = new Uint8Array(decryptedData, IV_LENGTH, outlen)
  const storedDgst = new Uint8Array(decryptedData, IV_LENGTH + outlen)

  const dgst = new Uint8Array(
    await crypto.subtle.digest(
      {
        name: 'SHA-256',
      },
      plaintext
    )
  )

  if (!equalBytes(storedDgst, dgst)) {
    throw new Error('Corrupt digest!')
  }

  return plaintext
}

/**
 * splits data into the parts designated by our crypto scheme
 *
 * The data layout is like this:
 * signature (HMAC-SHA256) - 32 bytes (0-31)
 * IV - 16 bytes (32-47)
 * payload - the rest (48-end)
 *
 * the HMAC signature contains IV + payload (bytes 32 - end)
 *
 * Note that this method is essentially zero-copy as it operates on the shared, immutable ArrayBuffer
 */
function splitDataIntoParts(data: Uint8Array) {
  const buffer = data.buffer
  return {
    signature: new Uint8Array(buffer, data.byteOffset + 0, HMAC_SIGNATURE_LENGTH),
    iv: new Uint8Array(buffer, data.byteOffset + HMAC_SIGNATURE_LENGTH, IV_LENGTH),
    payload: new Uint8Array(buffer, data.byteOffset + HMAC_SIGNATURE_LENGTH + IV_LENGTH),
    signedData: new Uint8Array(buffer, data.byteOffset + HMAC_SIGNATURE_LENGTH),
  }
}

/**
 * Generates key material for HMAC and AES using our crypto scheme
 *
 * @param baseKey the raw key
 */
async function generateKeys(baseKey: Uint8Array): Promise<Key> {
  const buffer = new Uint8Array(baseKey)
  const keyBuffer = new Uint8Array(buffer.byteLength + 1)

  keyBuffer.set(buffer, 0)
  keyBuffer.set(['1'.charCodeAt(0)], buffer.byteLength)
  const aesKeyBytes = await crypto.subtle.digest({ name: 'SHA-256' }, keyBuffer)

  keyBuffer.set(['2'.charCodeAt(0)], buffer.byteLength)
  const hmacKeyBytes = await crypto.subtle.digest({ name: 'SHA-256' }, keyBuffer)

  return {
    aes: await crypto.subtle.importKey('raw', aesKeyBytes, { name: 'AES-CBC', length: 256 }, true, [
      'encrypt',
      'decrypt',
    ]),
    hmac: await crypto.subtle.importKey(
      'raw',
      hmacKeyBytes,
      { name: 'HMAC', hash: 'SHA-256' },
      true,
      ['sign', 'verify']
    ),
    base: await crypto.subtle.importKey('raw', baseKey, { name: 'AES-CBC', length: 256 }, true, [
      'encrypt',
      'decrypt',
    ]),
  }
}

export function equalBytes(buf1: Uint8Array, buf2: Uint8Array) {
  if (buf1.byteLength != buf2.byteLength) {
    return false
  }

  let i = buf1.byteLength
  while (i--) {
    if (buf1[i] !== buf2[i]) {
      return false
    }
  }

  return true
}
