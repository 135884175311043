import { parseISO, isDate, isValid, format, formatDistanceToNow, min as minDate } from 'date-fns'
import { enUS, de, ar, el, es, fr, hr, it, nl, pl, pt, ru, tr } from 'date-fns/locale'
import { registerLocale } from 'react-datepicker'

import { getShortestPattern } from './getShortestPattern'
import ar_translation from './translations/ar.json'
import de_translation from './translations/de.json'
import el_translation from './translations/el.json'
import en_translation from './translations/en.json'
import es_translation from './translations/es.json'
import fr_translation from './translations/fr.json'
import hr_translation from './translations/hr.json'
import it_translation from './translations/it.json'
import nl_translation from './translations/nl.json'
import pl_translation from './translations/pl.json'
import pt_translation from './translations/pt.json'
import ru_translation from './translations/ru.json'
import tr_translation from './translations/tr.json'

export type AvailableLocale =
  | 'en'
  | 'de'
  | 'ar'
  | 'el'
  | 'es'
  | 'fr'
  | 'hr'
  | 'it'
  | 'nl'
  | 'pl'
  | 'pt'
  | 'ru'
  | 'tr'

const locales = { en: enUS, de, ar, el, es, fr, hr, it, nl, pl, pt, ru, tr }
let currentLocale = window.LOCALE as AvailableLocale

registerLocale(currentLocale, locales[currentLocale])

export function setTranslations(locale: AvailableLocale) {
  if (locale === 'ar') {
    window.translation = ar_translation
  } else if (locale === 'de') {
    window.translation = de_translation
  } else if (locale === 'el') {
    window.translation = el_translation
  } else if (locale === 'en') {
    window.translation = en_translation
  } else if (locale === 'es') {
    window.translation = es_translation
  } else if (locale === 'fr') {
    window.translation = fr_translation
  } else if (locale === 'hr') {
    window.translation = hr_translation
  } else if (locale === 'it') {
    window.translation = it_translation
  } else if (locale === 'nl') {
    window.translation = nl_translation
  } else if (locale === 'pl') {
    window.translation = pl_translation
  } else if (locale === 'pt') {
    window.translation = pt_translation
  } else if (locale === 'ru') {
    window.translation = ru_translation
  } else if (locale === 'tr') {
    window.translation = tr_translation
  } else {
    window.translation = {}
  }
  currentLocale = locale
}

if (!window.translation) {
  setTranslations(currentLocale)
}

if (!window.defaultTranslation) {
  if (window.DEFAULT_LOCALE === 'de') {
    window.defaultTranslation = de_translation
  } else {
    window.defaultTranslation = {}
  }
}

const interpolationPattern = /%\{(\w+)\}/g
const legacyInterpolationPattern = /%s/g

interface Translation {
  [key: string]: string
}

function parseInterpolations(values: Array<Object | string>) {
  const legacyInterpolations: string[] = []
  let interpolations: Translation = {}

  for (const val of values) {
    if (typeof val === 'string' || typeof val === 'number') {
      legacyInterpolations.push(String(val))
    } else {
      interpolations = Object.assign({}, interpolations, val)
    }
  }

  return { interpolations, legacyInterpolations }
}

export function replaceTranslationPatterns(term: string, ...values: Array<Object | string>) {
  const { interpolations, legacyInterpolations } = parseInterpolations(values)

  return term
    .replace(interpolationPattern, (key, val) => interpolations[val] || key)
    .replace(legacyInterpolationPattern, () => legacyInterpolations.shift() || '%s')
}

/*
 Supports following interpolation patterns
  t('foo.bar.baz', 100)
    en.json: foo.bar.baz = 'there is %s bazzes here'
  t('foo.bar.baz', 'bazzes')
    en.json: foo.bar.baz = 'many %s here'
  t('foo.bar.baz', { baz: 'water cooker', foo: 'dishwasher' })
    en.json: foo.bar.baz = 'this is %{foo} not %{baz}'
*/
export function t(key: string, ...values: Array<Object | string>) {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const translation = currentTranslations()
  const term = translation[key] === undefined ? key : translation[key]
  return replaceTranslationPatterns(term, ...values)
}

export function isKeyAvailable(key: string) {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const translation = currentTranslations()
  return !!translation[key] || translation[key] === ''
}

function currentTranslations() {
  return Object.assign({}, window.defaultTranslation, window.translation)
}

export function relativeTime(
  timeString: string,
  options: { addSuffix?: boolean; notAfter?: Date } = {}
) {
  let date = parseISO(timeString)

  if (options.notAfter) {
    date = minDate([date, options.notAfter])
  }

  return formatDistanceToNow(date, {
    addSuffix: options.addSuffix,
    locale: locales[currentLocale]
  })
}

/**
 * Formats the given JS Date or ISO8601 string
 *
 * formatting is done using date-fns, so for a list of formatting options see https://date-fns.org/v2.0.1/docs/format
 * alternatively you can provide a name of a custom pattern as a second argument
 *
 * NOTE: this is locale-aware! If you simply want to format a date according to the user's locale:
 *
 * dateFormat(date)
 * => "10.08.2019" (de locale)
 * => "08/28/2019" (en locale)
 *
 * formatting just the time with format code "p" (lowercase!)
 *
 * dateFormat(date, 'p')
 * => "13:30" (de locale)
 * => "01:30 PM" (en locale)
 *
 * formatting date and time combines "P" and "p"
 * dateFormat(date, 'Pp')
 * => "10.08.2019 13:30" (de locale)
 * => "08/28/2019 01:30 PM" (en locale)
 *
 * The more "p" you add, the longer the date format gets (REALLY, read the docs on the link above!)
 *
 * @param date The date to format
 * @param patternOrName date format pattern or name of a custom pattern
 */
export function dateFormat(date?: string | Date, patternOrName: string = 'P') {
  if (!date) {
    return
  }

  if (!isDate(date)) {
    date = parseISO(date.toString())
    if (!isValid(date)) {
      return
    }
  }

  let pattern = patternOrName

  switch (patternOrName) {
    case 'dateLong':
      if (currentLocale === 'en') {
        pattern = 'do LLLL yyyy'
      } else {
        pattern = 'd. LLLL yyyy'
      }
      break
    case 'dateTimeShort':
      if (currentLocale === 'de') {
        pattern = "d. MMM, H:mm 'Uhr'"
      } else {
        pattern = 'd. MMM, H:mm'
      }
      break
    case 'dateTime':
      if (currentLocale === 'de') {
        pattern = "Pp 'Uhr'"
      } else {
        pattern = 'Pp'
      }
      break
    case 'dateTimeLong':
      if (currentLocale === 'de') {
        pattern = "dd.MM.yyyy, H:mm 'Uhr'"
      } else {
        pattern = 'dd.MM.yyyy, H:mm'
      }
      break
    case 'dateTimeDynamic':
      const today = new Date()
      pattern = getShortestPattern(date as Date, today, currentLocale)
      break
    case 'dateTimeLongWithWeekDay':
      if (currentLocale === 'de') {
        pattern = "EEEE, dd.MMMM yyyy - HH:mm 'Uhr'"
      } else {
        pattern = 'EEEE, dd.MMMM yyyy - HH:mm'
      }
      break
    case 'dateTimeLongWithoutWeekDay':
      if (currentLocale === 'de') {
        pattern = "dd.MMMM yyyy - HH:mm 'Uhr'"
      } else {
        pattern = 'dd.MMMM yyyy - HH:mm'
      }
      break
    case 'time':
      if (currentLocale === 'de') {
        pattern = "p 'Uhr'"
      } else {
        pattern = 'p'
      }
      break
  }

  return format(date as Date, pattern, { locale: locales[currentLocale] })
}

export function getCurrentLocale() {
  return currentLocale || 'de'
}
