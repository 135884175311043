import { ByteBuffer } from 'node-forge/lib/util'

export function byteBufferToArrayBuffer(buf: ByteBuffer): Uint8Array {
  const len = buf.length()
  const out = new Uint8Array(len)

  for (let i = 0; i < len; ++i) {
    out[i] = buf.getByte()
  }

  return out
}
