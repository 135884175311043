import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { compose } from 'lodash/fp'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import 'style/verify_code.css'

import { FullscreenSpinner } from 'components/shared/FullscreenSpinner'
import { SelfDestruct } from 'components/shared/SelfDestruct/SelfDestruct'
import { SuccessToast } from 'components/shared/SuccessToast/SuccessToast'
import useLogin, { LoginFormValues } from 'hooks/useLogin'
import { t } from 'i18n'
import { isOtpInvalidError as getIsOtpInvalidError } from 'util/login'

import { FormValues } from './types'

import CodeVerification from '.'

export type CodeVerificationContainerProps = unknown

const CodeVerificationContainer: React.FC<CodeVerificationContainerProps> = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const authenticatedFormValues: LoginFormValues | null = location.state
  const [isOtpInvalidError, setIsOtpInvalidError] = useState(false)
  const { login, isLoading } = useLogin()

  useEffect(() => {
    if (authenticatedFormValues !== null) return

    navigate('/login')
  }, [authenticatedFormValues, navigate])

  const resendCodeMutation = useMutation(async () => {
    if (authenticatedFormValues === null) return

    try {
      await login(authenticatedFormValues)
    } catch (e) {
      // In case backend return OTP-required message, ignore error
      if (!(e instanceof AxiosError) || e.response?.status !== 400) {
        throw e
      }
    }
  })

  const handleSendCodeAgain = useCallback(() => {
    resendCodeMutation.mutate()
  }, [resendCodeMutation])

  const handleSubmit = useCallback(
    ({ code }: FormValues) => {
      if (authenticatedFormValues === null) return

      const handleRejected = compose(setIsOtpInvalidError, getIsOtpInvalidError)
      const values = {
        login: {
          ...authenticatedFormValues.login,
          otp_token: code
        }
      }

      login(values, handleRejected)
    },
    [authenticatedFormValues, login]
  )

  const clearError = useCallback(() => setIsOtpInvalidError(false), [setIsOtpInvalidError])

  if (authenticatedFormValues === null) return null

  const resendCodeSuccessToast = resendCodeMutation.isSuccess ? (
    <SelfDestruct expireAt={12000}>
      <SuccessToast className='top-16 z-[9999] md:top-9'>
        {t('login.verify-code.send-again-confirmation')}
      </SuccessToast>
    </SelfDestruct>
  ) : null

  if (resendCodeMutation.isError) {
    return (
      <div className='flex h-full w-full flex-col items-center justify-center bg-grey-150'>
        <div className='max-w-xl'>
          <div className='rounded-lg bg-white p-4'>{t('generic.loading_error')}</div>
        </div>
      </div>
    )
  }

  return (
    <>
      {resendCodeSuccessToast}
      <div className='flex h-full w-full flex-col items-center justify-center bg-grey-150'>
        <div className='max-w-xl'>
          {isLoading && <FullscreenSpinner text={t('login.verify-code.loading')} />}
          <div className='px-2 py-4'>
            <h3 className='pb-4 text-hero'>{t('login.verify-code.title')}</h3>
            <div className='mt-0 rounded-lg bg-white p-4'>
              <CodeVerification
                email={authenticatedFormValues.login.email}
                onChange={clearError}
                onSendCodeAgain={handleSendCodeAgain}
                onSubmit={handleSubmit}
                isInvalidInput={isOtpInvalidError}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CodeVerificationContainer
