/* eslint-disable @typescript-eslint/no-use-before-define */
/**
 * Handles events for:
 * 1) normal or api/auth user registration
 * 2) password reset
 */

import { captureException } from '@sentry/react'

import addHiddenFieldToForm from 'util/addHiddenFieldToForm'

const authUserForm: HTMLFormElement | null = document.querySelector('.js-user-form')

if (authUserForm) {
  authUserForm.addEventListener('submit', authUserFormHandler)
}

/**
 * Handles user form submission.
 * Creates a new user crypto key pair before submitting.
 */
async function authUserFormHandler(event: Event) {
  if (!event) return

  event.preventDefault()
  const passwordField: HTMLInputElement | null = document.querySelector('.js-user-password')
  const emailField: HTMLInputElement | null = document.querySelector('.js-user-email')

  if (authUserForm && passwordField && passwordField.value.length) {
    try {
      // Following lines might take some time, let's disable the button,
      // so user doesn't click it again
      const submitButton: HTMLButtonElement | null =
        document.querySelector('.js-user-submit-button')

      submitButton && submitButton.setAttribute('disabled', 'disabled')

      const { buildKeyPairForUser, generateSalt } = await import('services/encryption')
      const cryptorSalt = await generateSalt()

      const isQATestAutomation =
        ['review', 'staging'].includes(window.CONFIG.env) &&
        emailField?.value === 'qa_auto_registration@samedi.de'

      let keyPair

      if (isQATestAutomation) {
        keyPair = await buildKeyPairForUser(cryptorSalt, passwordField.value, 512)
      } else {
        keyPair = await buildKeyPairForUser(cryptorSalt, passwordField.value)
      }

      addHiddenFieldToForm('user[key_pair_json]', JSON.stringify(keyPair), authUserForm)
      addHiddenFieldToForm('user[cryptor_salt]', cryptorSalt, authUserForm)
    } catch (e) {
      captureException(e, { tags: { section: 'registration_debug' } })
    } finally {
      authUserForm.submit()
    }
  }
}
