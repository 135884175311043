import Leaflet from 'leaflet'
import 'leaflet/dist/leaflet.css'

import 'leaflet.markercluster/dist/leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

import './styles.css'

type Marker = {
  lat: number
  lon: number
  popupHtml: string
  alt: string
}

type MarkerToHighlight = { lat: number; lon: number }
const mapContainer = document.querySelector('#leaflet-map')

// FIXME: This implementation of the map does not clean after itself and will
//        leak memory. We do not need it for more than 1 render (we reload page
//        when we do not need it anymore) right now. Change this behaviour in
//        case you want to use it in more dynamic UI parts.
if (mapContainer) {
  const markers: Marker[] = JSON.parse(mapContainer.getAttribute('data-markers') || '[]')

  Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  })

  const map = Leaflet.map('leaflet-map', {
    minZoom: 2,
    // Do not let escape the World's edge
    maxBoundsViscosity: 1.0,
    maxBounds: Leaflet.latLngBounds(Leaflet.latLng(-90, -200), Leaflet.latLng(90, 200))
  })
  // Remove Leaflet's attribution
  map.attributionControl.setPrefix('')
  // In case there are no markers
  const defaultCenter = { lat: 52.52, lng: 13.4 }
  const defaultZoom = 13

  const basemap = Leaflet.tileLayer('https://maps.samedi.de/{z}/{x}/{y}.png', {
    attribution:
      '&copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map)

  map.setView(defaultCenter, defaultZoom)
  basemap.addTo(map)

  const markersLayer = Leaflet.markerClusterGroup()
  const leafletMarkers = markers.map((marker) => {
    const m = Leaflet.marker([marker.lat, marker.lon], { alt: marker.alt })
    m.bindPopup(marker.popupHtml, { keepInView: true }).openPopup()
    return m
  })

  leafletMarkers.forEach((marker) => markersLayer.addLayer(marker))

  const markersLayerBounds = markersLayer.getBounds()
  if (markersLayerBounds.isValid()) {
    map.fitBounds(markersLayerBounds)
    map.addLayer(markersLayer)
  }

  let circle: Leaflet.Circle | null = null

  const onHighlightMarker = (evt: { detail: MarkerToHighlight }) => {
    if (circle) {
      map.removeLayer(circle)
    }

    const latLngHighlight = new Leaflet.LatLng(evt.detail.lat, evt.detail.lon)
    const markerToHighlight = leafletMarkers.find((m) => m.getLatLng().equals(latLngHighlight))

    let circleBase = latLngHighlight

    if (markerToHighlight) {
      const visibleCluster = markersLayer.getVisibleParent(markerToHighlight)
      if (visibleCluster) {
        circleBase = visibleCluster.getLatLng()
      }
    }

    circle = Leaflet.circle(circleBase, {
      color: '#0067b2',
      fillColor: '#e6f0f7',
      fillOpacity: 0.5,
      radius: circleRadius(map.getZoom()) // eslint-disable-line @typescript-eslint/no-use-before-define
    })
    map.addLayer(circle)
  }

  // EventHandler can't be a `CustomEvent` for some reason in DOM implementation of TS
  // @ts-ignore
  mapContainer.addEventListener('highlightMarker', onHighlightMarker)
  mapContainer.addEventListener('unhighlightCurrentMarker', () => {
    if (circle) {
      map.removeLayer(circle)
    }
  })

  map.on('popupopen', (e) => {
    // Leaflet does not expose the reference to the marker from `Leaflet.Popup`.
    // We could try to find popup in the list of markers we hold, but that seems
    // like a bit too much work.
    // @ts-ignore
    const latLang: Leaflet.LatLng = e.popup._source.getLatLng()

    const practice = [...document.querySelectorAll<HTMLElement>('.c-search-result')].find(
      (practice) => {
        const practiceLatLang = new Leaflet.LatLng(
          parseFloat(practice.dataset.lat || '0'),
          parseFloat(practice.dataset.lon || '0')
        )
        return practiceLatLang.equals(latLang)
      }
    )

    if (practice) {
      practice.scrollIntoView()
    }
  })
}

function circleRadius(zoom: number): number {
  return 7.5 * Math.pow(2, 18 - zoom)
}
