import { t } from 'i18n'

const unmaskableFields = document.querySelectorAll('.js-unmaskable-field')

unmaskableFields.forEach((field) => {
  const wrapper = document.createElement('div')
  wrapper.classList.add('o-icon-wrapper')
  field.parentNode.insertBefore(wrapper, field)

  wrapper.appendChild(field)

  const button = document.createElement('button')
  button.type = 'button'
  button.innerHTML = "<span class='far fa-eye-slash'></span>"
  button.classList.add('o-icon-wrapper__icon')
  button.classList.add('o-clickable')
  button.setAttribute('aria-label', t('password.visibility_toggler.click_to_show'))

  wrapper.appendChild(button)

  button.addEventListener('click', (e) => {
    e.preventDefault()

    if (field.type === 'text') {
      field.type = 'password'
      button.setAttribute('aria-label', t('password.visibility_toggler.click_to_show'))
      button.innerHTML = "<span class='far fa-eye-slash'></span>"
    } else {
      field.type = 'text'
      button.setAttribute('aria-label', t('password.visibility_toggler.click_to_hide'))
      button.innerHTML = "<span class='far fa-eye'></span>"
    }
  })
})
