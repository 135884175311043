/*
 * Add class name 'js-checkbox' to the input field directly.
 * Checkbox is expected to be wrapped inside a label.
 */

const checkboxes = document.querySelectorAll('input.js-checkbox')

checkboxes.forEach((checkbox) => {
  checkbox.classList.add('o-checkbox')
  const parent = checkbox.parentNode
  parent.classList.add('o-checkbox-container')

  const checkmark = document.createElement('div')
  checkmark.className = 'o-checkbox__checkmark'
  parent.appendChild(checkmark)
})
