/**
 * getRandomValues uses a secure random source (provided by the browser) to fetch a number of random bytes
 *
 * This uses crypto.getRandomValues() internally
 *
 * @param size number of random bytes to fetch
 *
 * @return a Uint8Array containing the random bytes
 */
export function getRandomValues(size: number) {
  return crypto.getRandomValues(new Uint8Array(size))
}
