/**
 * Find all checkboxes that have `.js-switch` class,
 * replace them witch switch element which controls their state.
 */

const switches = document.querySelectorAll('input.js-switch')
switches.forEach((checkbox) => {
  const checkboxName = checkbox.name
  checkbox.style.display = 'none'
  const parent = checkbox.parentNode

  const switchWrapper = document.createElement('div')
  switchWrapper.classList.add('o-switch')

  if (checkbox.checked) {
    switchWrapper.classList.add('o-switch--is-active')
  }

  const switchHandle = document.createElement('div')
  switchHandle.classList.add('o-switch__handle')

  if (checkboxName) {
    switchWrapper.dataset.testid = `${checkboxName}-toggle-status`
    switchHandle.dataset.testid = `${checkboxName}-toggle`
  }

  switchWrapper.appendChild(switchHandle)
  parent.appendChild(switchWrapper)

  switchWrapper.addEventListener('click', () => {
    switchWrapper.classList.toggle('o-switch--is-active')
    const isActive = switchWrapper.classList.contains('o-switch--is-active')
    checkbox.checked = isActive
    const event = document.createEvent('Event')
    event.initEvent('change', true, true)
    checkbox.dispatchEvent(event)
  })
})
