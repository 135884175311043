import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import InfoContainer from 'components/shared/InfoContainer/InfoContainer'
import { t } from 'i18n'

export type InvalidInputErrorProps = {
  onSendCodeAgain: React.MouseEventHandler<HTMLButtonElement>
}

export const InvalidInputError: React.FC<InvalidInputErrorProps> = ({ onSendCodeAgain }) => (
  <InfoContainer variant='error' className='w-full'>
    <div className='flex gap-2 text-error-600'>
      <FontAwesomeIcon icon={faTriangleExclamation} className='h-4 w-4 px-1' />
      <div className='flex grow flex-col'>
        <InfoContainer.Text className='text-error-500'>
          {t('login.verify-code.error-invalid-input')}
        </InfoContainer.Text>
        <div>
          <button onClick={onSendCodeAgain}>
            <InfoContainer.Text className='pt-2 font-semibold text-error-500 underline'>
              {t('login.verify-code.error-send-again')}
            </InfoContainer.Text>
          </button>
        </div>
      </div>
    </div>
  </InfoContainer>
)
