const minimalPasswordLength = 6

const passwordField = document.querySelector('.js-password-requirements')
if (passwordField) {
  checkRequirements()
  passwordField.addEventListener('input', checkRequirements)
}

function checkRequirements() {
  const password = passwordField.value

  const isLongEnough = password.length >= minimalPasswordLength
  const hasNumbersOrSpecialCharacters = !password.match(/^[a-z]*$/i)
  const hasMixedCase = !!(password.match(/[a-z]/) && password.match(/[A-Z]/))

  toggleCheckbox('.js-password-requirements__length', isLongEnough)
  toggleCheckbox('.js-password-requirements__numbers-or-special', hasNumbersOrSpecialCharacters)
  toggleCheckbox('.js-password-requirements__mixed-case', hasMixedCase)

  const submitButton = document.querySelector('.js-password-requirements__submit-button')
  if (submitButton) {
    const allRequirementsSatisfied = isLongEnough && hasNumbersOrSpecialCharacters && hasMixedCase
    submitButton.disabled = !allRequirementsSatisfied
  }
}

function toggleCheckbox(selector, value) {
  const checkbox = document.querySelector(selector)
  if (value) {
    checkbox.classList.add('is-active')
  } else {
    checkbox.classList.remove('is-active')
  }
}
