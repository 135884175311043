const elementWithValidationMessage = document.querySelectorAll('[data-validation-message]')

elementWithValidationMessage.forEach((element) => {
  const validationMessage = element.dataset.validationMessage

  element.oninvalid = function () {
    this.setCustomValidity(validationMessage)
  }
  element.oninput = function () {
    this.setCustomValidity('')
  }

  /*
   * Since 'input' event is not supported for checkbox before Edge 79,
   * use 'change' event to void validity message if checkbox is checked.
   * Also set validity message manually to override the native one if it is unchecked.
   */

  element.onchange = function () {
    if (this.type === 'checkbox') {
      if (this.checked) {
        this.setCustomValidity('')
      } else {
        this.setCustomValidity(validationMessage)
      }
    }
  }
})
