/*
 * Disable the default behavior of the top menu item of a nested menu
 * Toggle second-level navigation menus when clicking on it
 */

const nestedMenus = document.querySelectorAll('.js-nested-menu__toggle')

nestedMenus.forEach((nestedMenu) => {
  nestedMenu.addEventListener('click', (e) => {
    nestedMenuBlock = nestedMenu.parentElement
    e.preventDefault()
    e.stopPropagation()
    nestedMenuBlock.classList.toggle('is-opened')
  })
})
