function getWrapperElement(element, className) {
  let parent = element.parentElement
  while (!parent.classList.contains(className)) {
    parent = parent.parentElement
  }
  return parent
}

initializeSwitches()
document.body.addEventListener('change', initializeSwitches)

function initializeSwitches() {
  const switches = document.querySelectorAll('.js-toggle__switch:not(.js-toggle--initialized)')
  switches.forEach((switchElement) => {
    switchElement.classList.add('js-toggle--initialized')
    switchElement.addEventListener('click', (e) => {
      const target = e.currentTarget
      const wrapperClass = target.dataset.toggleWrapper || 'js-toggle'
      const activeClass = target.dataset.toggleActiveClass || 'is-toggle-active'

      const parent = getWrapperElement(target, wrapperClass)
      parent.classList.toggle(activeClass)
    })
  })
}
