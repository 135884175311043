import React from 'react'
import ReactDOM from 'react-dom'

import CodeVerificationContainer from 'components/features/CodeVerification/CodeVerificationContainer'

const container = document.getElementById('js-verify-code-form')

if (container) {
  ReactDOM.render(<CodeVerificationContainer />, container)
}
