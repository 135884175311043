import jsbn from 'node-forge/lib/jsbn'
import { decode, encode } from './base64'

export function bytesToBigNum(bytes: Uint8Array) {
  const a = []
  const l = bytes.length

  // if we naively encode this, the highest bit is the sign bit.
  // Thus, when we encounter it set, we must add a 0x00 byte in front to denote it's NOT a negative number
  if ((bytes[0] & 0x80) !== 0) {
    a.push(0x00)
  }
  for (let i = 0; i < l; ++i) {
    a.push(bytes[i])
  }
  return new jsbn.BigInteger(a)
}

export function bigNumToBytes(bn: jsbn.BigInteger): Uint8Array {
  return new Uint8Array(bn.toByteArray())
}

export function base64ToBigNum(s: string) {
  return bytesToBigNum(decode(s))
}

export function bigNumToBase64(bn: jsbn.BigInteger) {
  return encode(bigNumToBytes(bn))
}
